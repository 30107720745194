import { Guid } from '@komo-tech/core/models/Guid';
import { useActionHandler } from '@komo-tech/ui/hooks/useActionHandler';
import { useEvent } from '@komo-tech/ui/hooks/useEvent';
import { useQueryClient } from '@tanstack/react-query';

import { AdminBillingSubscriptionCacheKeys } from '@/admin/data/Billing/Subscriptions/_cacheKeys';

import { AdminWorkspaceActions } from '../_actions';
import { AdminWorkspaceDeleteRequest } from '../_types';

interface Props {
  onSuccess?: (workspaceId: Guid | string) => void;
}

export const useWorkspaceDelete = ({ onSuccess }: Props) => {
  const [handler, { isHandling }] = useActionHandler();
  const queryClient = useQueryClient();

  const callback = useEvent((request: AdminWorkspaceDeleteRequest) =>
    handler(() => AdminWorkspaceActions.deleteAsync(request), {
      onSuccess: () => {
        queryClient.invalidateQueries(
          AdminBillingSubscriptionCacheKeys.baseUsage
        );
        onSuccess?.(request.workspaceId);
      }
    })
  );

  return [callback, isHandling] as const;
};
