import { forwardRef } from 'react';

import { IconProps } from './IconProps';
import { IconSvg } from './IconSvg';

export const ImageButtonIcon = forwardRef<HTMLSpanElement, IconProps>(
  ({ color, ...rest }, ref) => {
    return (
      <IconSvg ref={ref} {...rest}>
        <path
          clipRule="evenodd"
          d="m4,22c-1.1,0 -2,-0.9 -2,-2l0,-16c0,-1.1 0.9,-2 2,-2l16,0c1.1,0 2,0.9 2,2l0,5l0,3l0,3c0,0.55 -0.45,1 -1,1c-0.55,0 -1,-0.45 -1,-1l0,-3l0,-3l0,-4.5c0,-0.28 -0.22,-0.5 -0.5,-0.5l-15,0c-0.28,0 -0.5,0.22 -0.5,0.5l0,15c0,0.28 0.22,0.5 0.5,0.5l7.5,0l0,2l-8,0z"
          fill={color || 'currentColor'}
          fillRule="evenodd"
        />
        <path
          d="m8,9c0,1.1 0.9,2 2,2c1.1,0 2,-0.9 2,-2c0,-1.1 -0.9,-2 -2,-2c-1.1,0 -2,0.9 -2,2z"
          stroke={color || 'currentColor'}
          fill="none"
          strokeWidth="2"
        />
        <path
          d="m15.73,12.19l-0.59,-0.8l0.59,0.8zm0.55,-0.02l0.52,-0.86l-0.52,0.86zm-5.52,3.64l-0.59,-0.81l0.59,0.81zm-3.47,-1.65l0.49,-0.87l-0.49,0.87zm-3.69,3.64l3.74,-2.8l-1.2,-1.6l-3.74,2.8l1.2,1.6zm3.19,-2.77l2.93,1.68l1,-1.74l-2.94,-1.68l-0.99,1.74zm4.56,1.59l4.97,-3.62l-1.18,-1.61l-4.97,3.61l1.18,1.62zm4.42,-3.59l4.72,2.83l1.02,-1.72l-4.71,-2.83l-1.03,1.72zm0.55,-0.03c-0.16,0.12 -0.38,0.13 -0.55,0.03l1.03,-1.72c-0.52,-0.31 -1.17,-0.28 -1.66,0.08l1.18,1.61zm-6.6,3.71c0.52,0.29 1.15,0.26 1.63,-0.09l-1.18,-1.62c0.16,-0.11 0.37,-0.13 0.55,-0.03l-1,1.74zm-2.38,-1.71c-0.16,0.12 -0.38,0.13 -0.55,0.03l0.99,-1.74c-0.52,-0.29 -1.16,-0.25 -1.64,0.11l1.2,1.6z"
          fill={color || 'currentColor'}
        />
        <rect
          fill={color || 'currentColor'}
          height="9"
          rx="1.8"
          width="20"
          x="2"
          y="13"
        />
        <path
          d="m6.55,20.33c1.68,0 2.61,-1.03 2.61,-2.37c0,-0.55 -0.3,-0.87 -0.83,-0.87l-1.09,0c-0.38,0 -0.61,0.2 -0.61,0.55c0,0.36 0.24,0.56 0.61,0.56l0.32,0l0,0.07c-0.05,0.46 -0.43,0.76 -0.97,0.76c-0.73,0 -1.2,-0.62 -1.2,-1.64c0,-1.01 0.41,-1.59 1.11,-1.59c0.4,0 0.67,0.15 0.94,0.51c0.2,0.27 0.45,0.39 0.77,0.39c0.42,0 0.71,-0.28 0.71,-0.7c0,-0.14 -0.03,-0.27 -0.11,-0.42c-0.33,-0.6 -1.18,-1.08 -2.31,-1.08c-1.74,0 -2.8,1.06 -2.8,2.91c0,1.87 1.06,2.92 2.85,2.92z"
          fill="white"
        />
        <path
          d="m12.52,20.33c1.74,0 2.84,-1.11 2.84,-2.91c0,-1.8 -1.1,-2.92 -2.84,-2.92c-1.75,0 -2.84,1.12 -2.84,2.92c0,1.8 1.09,2.91 2.84,2.91zm0,-1.32c-0.7,0 -1.16,-0.62 -1.16,-1.59c0,-0.98 0.46,-1.6 1.16,-1.6c0.69,0 1.15,0.62 1.15,1.6c0,0.97 -0.46,1.59 -1.15,1.59z"
          fill="white"
        />
        <path
          clipRule="evenodd"
          d="m17.22,14.92c0.29,-0.29 0.77,-0.29 1.06,0l2,2c0.29,0.29 0.29,0.77 0,1.06l-2,2c-0.29,0.29 -0.77,0.29 -1.06,0c-0.29,-0.29 -0.29,-0.77 0,-1.06l1.47,-1.47l-1.47,-1.47c-0.29,-0.29 -0.29,-0.77 0,-1.06z"
          fill="white"
          fillRule="evenodd"
        />
      </IconSvg>
    );
  }
);
