import React, { forwardRef } from 'react';

import { IconProps } from './IconProps';
import { getDefaultColor, IconSvg } from './IconSvg';

export const AppleMusicIcon = forwardRef<HTMLSpanElement, IconProps>(
  ({ color, ...rest }, ref) => {
    return (
      <IconSvg ref={ref} {...rest}>
        <g clipPath="url(#prefix__clip0)">
          <path
            d="M23.996 6.125a9.27 9.27 0 00-.24-2.191c-.317-1.308-1.061-2.31-2.18-3.042A4.995 4.995 0 0019.7.165a10.593 10.593 0 00-1.565-.151c-.04-.002-.083-.008-.124-.012H5.99l-.455.026c-.747.042-1.49.124-2.192.401C2.004.96 1.04 1.881.475 3.208c-.191.45-.292.926-.362 1.408-.057.392-.09.785-.1 1.181-.002.03-.008.061-.011.092v12.223l.025.424c.05.814.155 1.624.498 2.372.65 1.42 1.737 2.353 3.233 2.801.422.127.856.188 1.293.228.554.053 1.11.062 1.666.062 3.677 0 7.353 0 11.03-.002.525 0 1.047-.033 1.569-.099.823-.105 1.597-.35 2.296-.81a5.038 5.038 0 001.88-2.208c.186-.422.292-.87.369-1.324.114-.675.138-1.358.137-2.04-.001-3.797.001-7.594-.002-11.391zm-6.422 3.99l-.001 5.711c0 .417-.057.827-.243 1.206-.288.591-.76.962-1.387 1.14a4.63 4.63 0 01-1.07.173 1.867 1.867 0 01-1.944-1.536 1.88 1.88 0 011.04-2.02c.323-.16.669-.25 1.018-.325.378-.082.757-.153 1.134-.24.274-.063.457-.23.51-.516a.976.976 0 00.018-.193V8.072a.75.75 0 00-.026-.184c-.04-.151-.15-.243-.304-.234-.16.009-.318.034-.475.066-.76.15-1.52.304-2.279.457l-2.327.47-1.374.277-.048.013c-.276.077-.377.204-.39.49v.13c-.001 2.602 0 5.204-.002 7.805 0 .42-.047.836-.215 1.226-.279.642-.77 1.041-1.435 1.235-.35.1-.71.159-1.075.171-.958.036-1.754-.6-1.918-1.544-.142-.813.23-1.685 1.152-2.076.357-.15.731-.232 1.108-.31.287-.06.574-.115.86-.177.382-.083.582-.323.599-.714v-.15c0-2.96 0-5.92.002-8.88 0-.124.013-.25.042-.37.07-.286.273-.448.546-.519.255-.066.515-.112.773-.165l2.199-.443 2.272-.459c.669-.135 1.338-.272 2.009-.404.22-.043.442-.088.664-.105.31-.025.522.17.554.481.008.074.012.15.012.224v5.731z"
            fill={color || getDefaultColor()}
          />
        </g>
        <defs>
          <clipPath id="prefix__clip0">
            <path
              fill="#fff"
              transform="translate(.003)"
              d="M0 0h23.994v24H0z"
            />
          </clipPath>
        </defs>
      </IconSvg>
    );
  }
);
