import { forwardRef } from 'react';

import { IconProps } from './IconProps';
import { getDefaultColor, IconSvg } from './IconSvg';

export const MultiPollIcon = forwardRef<HTMLSpanElement, IconProps>(
  ({ color, ...rest }, ref) => {
    return (
      <IconSvg {...rest} ref={ref}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11 3C11 2.44772 11.4477 2 12 2C12.5523 2 13 2.44772 13 3V21C13 21.5523 12.5523 22 12 22C11.4477 22 11 21.5523 11 21V3ZM5 10C5 9.44772 5.44772 9 6 9C6.55228 9 7 9.44772 7 10V21C7 21.5523 6.55228 22 6 22C5.44772 22 5 21.5523 5 21V10ZM18 11C17.4477 11 17 11.4477 17 12V21C17 21.5523 17.4477 22 18 22C18.5523 22 19 21.5523 19 21V12C19 11.4477 18.5523 11 18 11ZM16.6142 6C16.275 6 16 5.72502 16 5.38582C16 5.04662 16.275 4.77164 16.6142 4.77164H18.7716V2.85822C18.7716 2.51902 19.0466 2.24405 19.3858 2.24405C19.725 2.24405 20 2.51902 20 2.85822V4.77164H22.1418C22.481 4.77164 22.756 5.04662 22.756 5.38582C22.756 5.72502 22.481 6 22.1418 6H20V8.38582C20 8.72502 19.725 9 19.3858 9C19.0466 9 18.7716 8.72502 18.7716 8.38582V6H16.6142Z"
          fill={color || getDefaultColor()}
        />
      </IconSvg>
    );
  }
);
