import React, { forwardRef } from 'react';

import { IconProps } from './IconProps';
import { getDefaultColor, IconSvg } from './IconSvg';

export const GoogleMapsCardIcon = forwardRef<HTMLSpanElement, IconProps>(
  ({ color, ...rest }, ref) => {
    return (
      <IconSvg ref={ref} {...rest}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.4029 0.871473C13.6403 0.630881 12.8232 0.499237 11.9788 0.499237C9.51389 0.499237 7.30771 1.61141 5.83239 3.36364L5.83243 3.36367C4.61721 4.80704 3.95154 6.63364 3.95313 8.52046C3.95313 10.0276 4.25273 11.2532 4.74753 12.3472L9.62291 6.55033L9.62287 6.5503L14.0388 1.30397L9.63922 6.54081C9.92471 6.20306 10.2801 5.93116 10.6809 5.74391C11.0872 5.55407 11.5303 5.45589 11.9789 5.45633C12.564 5.45686 13.1368 5.6246 13.6299 5.93978C14.1229 6.25496 14.5156 6.70446 14.7617 7.23534C15.0078 7.76622 15.0971 8.35638 15.0191 8.93632C14.9474 9.4692 14.7371 9.97307 14.4104 10.3981L14.2407 10.6001C13.9674 10.8982 13.6375 11.1395 13.2698 11.3098C12.8652 11.4972 12.4247 11.5941 11.9788 11.5937C10.2856 11.5937 8.91014 10.2182 8.91014 8.52498C8.90816 7.80668 9.16047 7.11095 9.62223 6.56096L4.74745 12.3517C5.38128 13.7538 6.33141 14.9466 7.37567 16.2577L7.37568 16.2577L7.37573 16.2578C7.70757 16.6744 8.04892 17.103 8.39264 17.554L11.7314 13.5863L8.39731 17.5541C8.85126 18.1488 9.30974 18.7798 9.75461 19.4789C10.5183 20.6606 10.8238 21.6198 11.042 22.3047C11.2882 23.0773 11.4231 23.5008 11.9789 23.5008C12.5346 23.5008 12.6696 23.0763 12.9156 22.303C13.1338 21.617 13.4393 20.6565 14.2033 19.4743C14.9854 18.252 15.8051 17.2245 16.5737 16.261C18.4371 13.9252 20.0002 11.9658 20.0002 8.52059C20.0002 7.18599 19.6733 5.92856 19.0968 4.82093L19.0939 4.82442C18.1133 2.93877 16.425 1.51885 14.3989 0.87618L14.4029 0.871473Z"
          fill={color || getDefaultColor()}
        />
      </IconSvg>
    );
  }
);
