import { Guid } from '@komo-tech/core/models/Guid';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { AdminBillingSubscriptionUsage } from '@/common/models/billing/subscriptions/Admin/AdminBillingSubscriptionUsage';

import { AdminBillingSubscriptionActions } from '../_actions';
import { AdminBillingSubscriptionCacheKeys } from '../_cacheKeys';

export const useAdminBillingSubscriptionGetUsage = (
  id: Guid | string,
  options: Partial<UseQueryOptions<AdminBillingSubscriptionUsage>> = {}
) =>
  useQuery(
    AdminBillingSubscriptionCacheKeys.getUsage(id),
    () => AdminBillingSubscriptionActions.getUsageAsync(id),
    {
      refetchOnWindowFocus: false,
      ...options
    }
  );
