import { Guid } from '@komo-tech/core/models/Guid';

import { HubCampaignWithTrackingModel } from '../companies/HubCampaignWithTrackingModel';

export class SiteUnPublishInfo {
  siteId: Guid;
  activeCampaign?: HubCampaignWithTrackingModel;

  get hasCampaign() {
    return this.activeCampaign ? true : false;
  }

  constructor(props?: Partial<SiteUnPublishInfo>) {
    props = props || {};
    Object.assign(this, props);

    this.siteId = Guid.valueOrEmpty(props.siteId);
    if (props.activeCampaign) {
      this.activeCampaign = new HubCampaignWithTrackingModel(
        props.activeCampaign
      );
    }
  }
}
