import { useMemo } from 'react';

import { useAdministrator, useCompany } from '@/admin/components/PortalContext';
import { AdministratorRoles } from '@/admin/models/AdministratorRoles';
import { AdministratorTypes } from '@/admin/models/AdministratorTypes';

export interface UseHasPermissionProps {
  type?: AdministratorTypes;
  role?: AdministratorRoles;
}

export const useHasPermission = ({ type, role }: UseHasPermissionProps) => {
  const administrator = useAdministrator();
  const company = useCompany();

  return useMemo(() => {
    if (!administrator) return false;
    return administrator.hasPermission(company?.id, type, role);
  }, [administrator, company, type, role]);
};

export const useIsSuperUser = () => {
  return useHasPermission({ type: AdministratorTypes.SuperUser });
};
