import React, { forwardRef } from 'react';

import { IconProps } from './IconProps';
import { IconSvg } from './IconSvg';

export const WordsOrLessIcon = forwardRef<HTMLSpanElement, IconProps>(
  ({ color, ...rest }, ref) => {
    return (
      <IconSvg ref={ref} {...rest}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.1864 4.20709C18.3967 3.9968 18.6819 3.87866 18.9793 3.87866C19.1265 3.87866 19.2723 3.90767 19.4084 3.96402C19.5444 4.02037 19.668 4.10297 19.7722 4.20709C19.8763 4.31121 19.9589 4.43483 20.0152 4.57087C20.0716 4.70692 20.1006 4.85273 20.1006 4.99998C20.1006 5.14724 20.0716 5.29305 20.0152 5.42909C19.9589 5.56514 19.8763 5.68875 19.7722 5.79288L7.46802 18.097L5.35364 18.6256L5.88223 16.5112L18.1864 4.20709ZM18.9793 1.87866C18.1514 1.87866 17.3575 2.20751 16.7722 2.79288L4.27216 15.2929C4.144 15.421 4.05308 15.5816 4.00913 15.7574L3.00913 19.7574C2.92393 20.0982 3.02378 20.4587 3.27216 20.7071C3.52054 20.9555 3.88103 21.0553 4.2218 20.9701L8.22181 19.9701C8.39764 19.9262 8.55822 19.8352 8.68638 19.7071L21.1864 7.20709C21.4762 6.91725 21.7061 6.57316 21.863 6.19446C22.0199 5.81576 22.1006 5.40988 22.1006 4.99998C22.1006 4.59008 22.0199 4.1842 21.863 3.8055C21.7061 3.42681 21.4762 3.08272 21.1864 2.79288C20.8965 2.50303 20.5524 2.27312 20.1737 2.11626C19.7951 1.9594 19.3892 1.87866 18.9793 1.87866ZM12.9792 19C12.427 19 11.9792 19.4477 11.9792 20C11.9792 20.5523 12.427 21 12.9792 21H21.9792C22.5315 21 22.9792 20.5523 22.9792 20C22.9792 19.4477 22.5315 19 21.9792 19H12.9792Z"
          fill={color || 'currentColor'}
        />
      </IconSvg>
    );
  }
);
