import { Guid } from '@komo-tech/core/models/Guid';
import { QueryParams } from '@komo-tech/core/models/QueryParams';

import { AdminLiveApi } from '@/admin/data/AdminApi';
import { AdminBillingSubscription } from '@/common/models/billing/subscriptions/Admin/AdminBillingSubscription';
import { AdminBillingSubscriptionUsage } from '@/common/models/billing/subscriptions/Admin/AdminBillingSubscriptionUsage';

import {
  AdminBillingSubscriptionCloneRequest,
  AdminBillingSubscriptionCreateRequest,
  AdminBillingSubscriptionSearchQuery,
  AdminBillingSubscriptionUpdateRequest
} from './_types';

export const AdminBillingSubscriptionActions = {
  getAsync: async (id: Guid | string) => {
    return new AdminBillingSubscription(
      await AdminLiveApi.getAsync<AdminBillingSubscription>(
        `billing/subscriptions/${id}`
      )
    );
  },
  searchAsync: async ({
    take,
    skip,
    ...rest
  }: AdminBillingSubscriptionSearchQuery) =>
    AdminLiveApi.getPageAsync(
      `billing/subscriptions${new QueryParams(rest).setTakeSkip(take, skip).toString()}`,
      (x) => new AdminBillingSubscription(x)
    ),
  getUsageAsync: async (id: Guid | string) => {
    return new AdminBillingSubscriptionUsage(
      await AdminLiveApi.getAsync<AdminBillingSubscriptionUsage>(
        `billing/subscriptions/${id}/usage`
      )
    );
  },
  createAsync: async (request: AdminBillingSubscriptionCreateRequest) => {
    return new AdminBillingSubscription(
      await AdminLiveApi.postAsync<AdminBillingSubscription>(
        `billing/subscriptions`,
        request
      )
    );
  },
  updateAsync: async ({
    billingSubscriptionId,
    billingSubscription
  }: AdminBillingSubscriptionUpdateRequest) => {
    return new AdminBillingSubscription(
      await AdminLiveApi.putAsync<AdminBillingSubscription>(
        `billing/subscriptions/${billingSubscriptionId}`,
        billingSubscription
      )
    );
  },
  cloneAsync: async ({
    billingSubscriptionId,
    ...request
  }: AdminBillingSubscriptionCloneRequest) => {
    return new AdminBillingSubscription(
      await AdminLiveApi.postAsync<AdminBillingSubscription>(
        `billing/subscriptions/${billingSubscriptionId}/clone`,
        request
      )
    );
  }
};
