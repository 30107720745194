import {
  AsyncActionHandler,
  useActionHandler,
  UseHandleActionOptions
} from '@komo-tech/ui/hooks/useActionHandler';
import { QueryClient, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { AdminBillingSubscription } from '@/common/models/billing/subscriptions/Admin/AdminBillingSubscription';

import { AdminBillingSubscriptionActions } from '../_actions';
import { AdminBillingSubscriptionCacheKeys } from '../_cacheKeys';
import { AdminBillingSubscriptionCloneRequest } from '../_types';

export type BillingSubscriptionCloneHookAction = (
  request: AdminBillingSubscriptionCloneRequest,
  onSuccess?: (subscription: AdminBillingSubscription) => void
) => Promise<AdminBillingSubscription>;

interface HandleConfig
  extends UseHandleActionOptions<AdminBillingSubscription> {
  handler: AsyncActionHandler;
  queryClient: QueryClient;
}

const useControlled = (config: HandleConfig) => {
  return useCallback<BillingSubscriptionCloneHookAction>(
    handleCloneAsync(config),
    [config.queryClient]
  );
};

const useUncontrolled = (
  options?: UseHandleActionOptions<AdminBillingSubscription>
) => {
  const [handler, { isHandling }] = useActionHandler();
  const queryClient = useQueryClient();
  const controlledOptions = (options || {}) as any as HandleConfig;
  controlledOptions.queryClient = queryClient;
  controlledOptions.handler = handler;

  const handleAsync = useControlled(controlledOptions);

  return [handleAsync, isHandling] as const;
};

const handleCloneAsync = ({
  handler,
  queryClient,
  source = 'BILLING_SUBSCRIPTION_CLONE',
  onSuccess: configOnSuccess,
  ...configRest
}: HandleConfig) => {
  return (
    request: AdminBillingSubscriptionCloneRequest,
    onSuccess?: (subscription: AdminBillingSubscription) => void
  ) => {
    return handler(() => AdminBillingSubscriptionActions.cloneAsync(request), {
      source,
      throwOnError: false,
      noToastOnError: false,
      onSuccess: (subscription) => {
        AdminBillingSubscriptionCacheKeys.fns.updateAsync(
          queryClient,
          subscription
        );
        onSuccess?.(subscription);
        configOnSuccess?.(subscription);
      },
      ...configRest
    });
  };
};

export const useAdminBillingSubscriptionClone = {
  uncontrolled: useUncontrolled,
  controlled: useControlled
};
