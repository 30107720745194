import { forwardRef } from 'react';

import { IconProps } from './IconProps';
import { getDefaultColor, IconSvg } from './IconSvg';

const strokeWidth = 1.5;

export const LiveSurveyIcon = forwardRef<HTMLSpanElement, IconProps>(
  ({ color, ...rest }, ref) => {
    const strokeColor = color || getDefaultColor();

    return (
      <IconSvg ref={ref} {...rest}>
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path
          stroke={strokeColor}
          strokeWidth={strokeWidth}
          fill="transparent"
          d="M21 14l-3 -3h-7a1 1 0 0 1 -1 -1v-6a1 1 0 0 1 1 -1h9a1 1 0 0 1 1 1v10"
        />
        <path
          stroke={strokeColor}
          strokeWidth={strokeWidth}
          fill="transparent"
          d="M14 15v2a1 1 0 0 1 -1 1h-7l-3 3v-10a1 1 0 0 1 1 -1h2"
        />
      </IconSvg>
    );
  }
);
