import { forwardRef } from 'react';

import { IconProps } from './IconProps';
import { IconSvg } from './IconSvg';

export const YoutubeIcon = forwardRef<HTMLSpanElement, IconProps>(
  ({ color, ...rest }, ref) => {
    return (
      <IconSvg {...rest} ref={ref}>
        <path
          d="M21.582 6.186C21.352 5.326 20.674 4.648 19.814 4.418C18.254 4 12 4 12 4C12 4 5.746 4 4.186 4.418C3.325 4.648 2.648 5.326 2.418 6.186C2 7.746 2 12 2 12C2 12 2 16.254 2.418 17.814C2.648 18.675 3.326 19.352 4.186 19.582C5.746 20 12 20 12 20C12 20 18.254 20 19.814 19.582C20.674 19.352 21.352 18.674 21.582 17.814C22 16.254 22 12 22 12C22 12 22 7.746 21.582 6.186ZM19.65 17.297C19.605 17.466 19.466 17.604 19.296 17.65C18.491 17.866 14.882 18 12 18C9.119 18 5.51 17.866 4.703 17.65C4.534 17.605 4.396 17.466 4.35 17.296C4.109 16.398 4 13.658 4 12C4 10.342 4.109 7.602 4.35 6.702C4.395 6.534 4.534 6.395 4.704 6.35C5.509 6.134 9.118 6 12 6C14.882 6 18.491 6.134 19.297 6.35C19.466 6.395 19.604 6.534 19.65 6.704C19.891 7.602 20 10.342 20 12C20 13.658 19.891 16.398 19.65 17.297Z"
          fill={color || 'currentColor'}
        />
        <path
          d="M10 15.464V8.53601L16 12L10 15.464Z"
          fill={color || 'currentColor'}
        />
      </IconSvg>
    );
  }
);
