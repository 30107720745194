import { Page } from '@komo-tech/core/models/Page';
import { useEvent } from '@komo-tech/ui/hooks/useEvent';
import { usePagination } from '@komo-tech/ui/Pagination';
import {
  useQuery,
  useQueryClient,
  UseQueryOptions
} from '@tanstack/react-query';

import { useQueryEvents } from '@/common/hooks/useQueryEvents';
import { AdminBillingSubscription } from '@/common/models/billing/subscriptions/Admin/AdminBillingSubscription';

import { AdminBillingSubscriptionActions } from '../_actions';
import { AdminBillingSubscriptionCacheKeys } from '../_cacheKeys';
import { AdminBillingSubscriptionSearchQuery } from '../_types';

interface SearchProps
  extends Omit<AdminBillingSubscriptionSearchQuery, 'take' | 'skip'> {
  pageSize?: number;
  options?: UseQueryOptions<Page<AdminBillingSubscription>>;
}

export const useAdminBillingSubscriptionSearch = ({
  pageSize = 50,
  options,
  ...rest
}: SearchProps) => {
  const queryClient = useQueryClient();
  const pagination = usePagination(pageSize);

  const request = {
    take: pagination.take,
    skip: pagination.skip,
    ...rest
  };
  const cacheKey = AdminBillingSubscriptionCacheKeys.search(request);

  const query = useQuery<Page<AdminBillingSubscription>>(
    cacheKey,
    () => AdminBillingSubscriptionActions.searchAsync(request),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      onError: (err) => console.error(err),
      ...options
    }
  );

  const refetchAsync = useEvent(query.refetch);

  useQueryEvents(query, {
    onSuccess: (p) => pagination.setDefaults(p.items.length, p.totalItems)
  });

  return {
    hasData: !!query?.data?.items?.length,
    isLoading: query.isLoading,
    isError: query.isError,
    isFetching: query.isFetching,
    error: query.error,
    data: query.data ?? Page.empty<AdminBillingSubscription>(),
    pagination,
    cacheKey,
    invalidateAsync: () => queryClient.invalidateQueries(cacheKey),
    setData: (data: Page<AdminBillingSubscription>) =>
      queryClient.setQueryData(cacheKey, data),
    refetchAsync
  };
};
