import { useHotkeys } from '@komo-tech/ui/hooks/useHotkeys';
import {
  useColorScheme,
  useComputedColorScheme
} from '@komo-tech/ui/theme/ColorScheme';

export const useAdminShortcuts = (isSuperUser?: boolean) => {
  const { setColorScheme } = useColorScheme();
  const computedColorScheme = useComputedColorScheme('light', {
    getInitialValueInEffect: true
  });

  const handleToggleColorScheme = () => {
    if (!isSuperUser) return;
    setColorScheme(computedColorScheme === 'light' ? 'dark' : 'light');
  };

  useHotkeys([['mod+alt+J', handleToggleColorScheme]]);
};
