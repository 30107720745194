import {
  ThemeIcon as MantineThemeIcon,
  ThemeIconProps as MantineThemeIconProps
} from '@mantine/core';

/**
 * @see https://mantine.dev/core/theme-icon/
 */
export const ThemeIcon = MantineThemeIcon;
export type ThemeIconProps = MantineThemeIconProps;
