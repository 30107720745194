import React, { forwardRef } from 'react';

import { IconProps } from './IconProps';
import { getDefaultColor, IconSvg } from './IconSvg';

export const InstagramCardIcon = forwardRef<HTMLSpanElement, IconProps>(
  ({ color, ...rest }, ref) => {
    return (
      <IconSvg ref={ref} {...rest}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.55556 2H16.4444C19.5078 2 22 4.49222 22 7.55556V16.4444C22 19.5078 19.5078 22 16.4444 22H7.55556C4.49222 22 2 19.5078 2 16.4444V7.55556C2 4.49222 4.49222 2 7.55556 2ZM16.4444 19.7778C18.2822 19.7778 19.7778 18.2822 19.7778 16.4444V7.55556C19.7778 5.71778 18.2822 4.22222 16.4444 4.22222H7.55556C5.71778 4.22222 4.22222 5.71778 4.22222 7.55556V16.4444C4.22222 18.2822 5.71778 19.7778 7.55556 19.7778H16.4444ZM6.44458 12C6.44458 8.93667 8.9368 6.44444 12.0001 6.44444C15.0635 6.44444 17.5557 8.93667 17.5557 12C17.5557 15.0633 15.0635 17.5556 12.0001 17.5556C8.9368 17.5556 6.44458 15.0633 6.44458 12ZM8.6668 12C8.6668 13.8378 10.1624 15.3333 12.0001 15.3333C13.8379 15.3333 15.3335 13.8378 15.3335 12C15.3335 10.1622 13.8379 8.66667 12.0001 8.66667C10.1624 8.66667 8.6668 10.1622 8.6668 12ZM16.77 5.65877C16.9784 5.4504 17.261 5.33333 17.5557 5.33333C17.8504 5.33333 18.133 5.4504 18.3414 5.65877C18.5497 5.86714 18.6668 6.14976 18.6668 6.44444C18.6668 6.73913 18.5497 7.02174 18.3414 7.23012C18.133 7.43849 17.8504 7.55556 17.5557 7.55556C17.261 7.55556 16.9784 7.43849 16.77 7.23012C16.5616 7.02174 16.4446 6.73913 16.4446 6.44444C16.4446 6.14976 16.5616 5.86714 16.77 5.65877Z"
          fill={color || getDefaultColor()}
        />
      </IconSvg>
    );
  }
);
