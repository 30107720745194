import { OperationResult } from '@komo-tech/core/models/OperationResult';
import {
  AsyncActionHandler,
  useActionHandler,
  UseHandleActionOptions
} from '@komo-tech/ui/hooks/useActionHandler';
import { ToasterContextState } from '@komo-tech/ui/Toast/Context';

import {
  AdministratorSession,
  SessionResponseCode
} from '@/admin/models/AdministratorSession';

import { AdminAuthActions } from '../_actions';
import { AdminOAuthTypes, OAuthVerifyRequest } from '../_types';

interface HandleOverrides
  extends UseHandleActionOptions<
    OperationResult<SessionResponseCode, AdministratorSession>
  > {}

interface HandleConfig extends HandleOverrides {
  onRouterPush: (path: string) => void;
  handler: AsyncActionHandler;
  toaster: ToasterContextState;
}

const useControlled = (config: HandleConfig) => handleLoginAsync(config);

const useUncontrolled = (
  options: Pick<HandleConfig, 'onRouterPush'> & HandleOverrides
) => {
  const [handler, { isHandling }] = useActionHandler();
  const controlledOptions = (options || {}) as any as HandleConfig;
  controlledOptions.handler = handler;
  const handleAsync = useControlled(controlledOptions);

  return [handleAsync, isHandling] as const;
};

const handleLoginAsync = ({
  handler,
  onRouterPush,
  source: sourceProp,
  toaster,
  onSuccess: configOnSuccess,
  noToastOnError,
  noLogOnError,
  ...configRest
}: HandleConfig) => {
  return (
    {
      type,
      code,
      companyId,
      redirectUrl
    }: Omit<OAuthVerifyRequest, 'origin'> & {
      type: AdminOAuthTypes;
      redirectUrl?: string;
    },
    options?: Pick<HandleOverrides, 'onSuccess'>
  ) => {
    const request: OAuthVerifyRequest = {
      code,
      origin: window.location.origin,
      companyId
    };

    let source = sourceProp;
    let action: (
      request: OAuthVerifyRequest
    ) => Promise<OperationResult<SessionResponseCode, AdministratorSession>>;
    switch (type) {
      case 'google':
        action = AdminAuthActions.loginViaGoogleAuth;
        if (!source) source = 'ADMIN_LOGIN_GOOGLE';
        break;

      case 'microsoft':
        action = AdminAuthActions.loginViaMicrosoftAuthAsync;
        if (!source) source = 'ADMIN_LOGIN_MICROSOFT';
        break;
      default:
        const unsupported: never = type;
        console.error(`Unknown OAuth type: ${unsupported}`);
        throw new Error(`Unknown OAuth type: ${unsupported}`);
    }

    return handler(() => action(request), {
      onSuccess: (result) => {
        if (result.success) {
          AdminAuthActions.loginRedirectAndSession(
            result.data,
            onRouterPush,
            redirectUrl
          );
        } else {
          if (!noToastOnError) {
            toaster.error(result.errorMessage);
          }
          if (!noLogOnError) {
            console.error(result.errorMessage);
          }
        }

        configOnSuccess?.(result);
        options?.onSuccess?.(result);
      },
      source,
      ...configRest
    });
  };
};

export const useAdminOAuthLogin = {
  uncontrolled: useUncontrolled,
  controlled: useControlled
};
