import React, { forwardRef } from 'react';

import { IconProps } from './IconProps';
import { IconSvg } from './IconSvg';

export const FacebookCardIcon = forwardRef<HTMLSpanElement, IconProps>(
  ({ color, ...rest }, ref) => {
    return (
      <IconSvg ref={ref} {...rest}>
        <g clipPath="url(#clip0)">
          <path
            d="M23.9932 10.6843C23.9946 10.6669 23.9986 10.6508 23.9999 10.6334C23.9986 10.6495 23.9946 10.6669 23.9932 10.6843ZM-0.000488281 10.628C0.00219213 10.6508 0.00621271 10.6736 0.00889313 10.6964C0.00621271 10.675 0.000851926 10.6522 -0.000488281 10.628ZM13.6321 12.6062H16.7534L17.244 9.43526H13.6321V7.70238C13.6321 6.38495 14.0623 5.21763 15.2953 5.21763H17.2748V2.45011C16.9263 2.4032 16.1906 2.3 14.8008 2.3C11.8979 2.3 10.1958 3.8332 10.1958 7.32578V9.43526H7.21117V12.6062H10.1958V21.6607H13.6321V12.6062Z"
            fill={color || 'currentColor'}
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </IconSvg>
    );
  }
);
