import {
  createSpotlight as createMantineSpotlight,
  Spotlight as MantineSpotlight,
  spotlight as mantineSpotlight,
  SpotlightActionData as MantineSpotlightActionData,
  SpotlightActionProps as MantineSpotlightActionProps
} from '@mantine/spotlight';

/**
 * @see https://mantine.dev/others/Spotlight/
 */
export const createSpotlight = createMantineSpotlight;
export const spotlight = mantineSpotlight;
export const Spotlight = MantineSpotlight;
export type SpotlightActionData = MantineSpotlightActionData;
export interface SpotlightActionProps extends MantineSpotlightActionProps {}
