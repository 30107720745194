import { AdminBillingSubscription } from '@/common/models/billing/subscriptions/Admin/AdminBillingSubscription';
import { AdminBillingSubscriptionUsage } from '@/common/models/billing/subscriptions/Admin/AdminBillingSubscriptionUsage';
import { BillingSubscriptionEntitlement } from '@/common/models/billing/subscriptions/shared/BillingSubscriptionEntitlement';

export const resolveSubscriptionEntitlements = (
  subscription?: AdminBillingSubscription,
  usage?: AdminBillingSubscriptionUsage
) => {
  if (!subscription) {
    return [];
  }

  const entitlements: BillingSubscriptionEntitlement[] = [];
  const plan = subscription.plan;
  for (const feature of plan.features) {
    entitlements.push(
      new BillingSubscriptionEntitlement({
        name: feature.definition.name,
        description: feature.definition.description,
        order: feature.definition.order,
        featureType: feature.type,
        featureCategory: feature.definition.category,
        variableType: feature.definition.variableType,
        value: feature.value,
        usage: usage?.data[feature.type] ?? null
      })
    );
  }

  for (const modifier of subscription.modifiers) {
    const entitlement = entitlements.find(
      (x) => x.featureType === modifier.featureType
    );

    if (entitlement) {
      entitlement.modifier = modifier;
    } else {
      entitlements.push(
        new BillingSubscriptionEntitlement({
          name: modifier.featureDefinition.name,
          description: modifier.featureDefinition.description,
          order: modifier.featureDefinition.order,
          featureType: modifier.featureType,
          featureCategory: modifier.featureDefinition.category,
          variableType: modifier.featureDefinition.variableType,
          usage: usage?.data[modifier.featureType] ?? null,
          modifier,
          modifierOnly: true
        })
      );
    }
  }

  return entitlements.sort(
    (a, b) =>
      a.featureCategory.order - b.featureCategory.order || a.order - b.order
  );
};
