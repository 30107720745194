import { Guid } from '@komo-tech/core/models/Guid';
import { mapArray } from '@komo-tech/core/utils/array';
import { asBoolean } from '@komo-tech/core/utils/boolean';
import { asNumber } from '@komo-tech/core/utils/number';

import {
  ContactPropertyOptionModel,
  resolveContactSchemaFromOptions
} from '@/common/models/contacts/Admin/ContactPropertyOptionModel';

import { HubCampaignWithTrackingModel } from '../companies/HubCampaignWithTrackingModel';
import {
  FeatureTrackingModel,
  HasFeatureAccessResponse
} from '../pricing/FeatureTrackingModel';
import { PriceFeatures } from '../pricing/PriceFeatures';
import { PricePlanProperties } from '../pricing/PricePlanProperties';

export class AdminSiteOptions {
  availablePlans: AdminSiteOptionsPlan[];
  activeCampaign?: HubCampaignWithTrackingModel;
  isTemplatesCompany: boolean;
  contactProperties: ContactPropertyOptionModel[];

  get contactSchema() {
    return resolveContactSchemaFromOptions(this.contactProperties);
  }

  constructor(props?: Partial<AdminSiteOptions>) {
    props = props || {};
    Object.assign(this, props);
    this.isTemplatesCompany = asBoolean(this.isTemplatesCompany);
    this.contactProperties = mapArray(this.contactProperties, (x) => ({
      ...x
    }));
    this.availablePlans = (this.availablePlans || []).map(
      (x) => new AdminSiteOptionsPlan(x)
    );
    if (this.activeCampaign) {
      this.activeCampaign = new HubCampaignWithTrackingModel(
        this.activeCampaign
      );
    }
  }

  getPlanForFeature(feature: PriceFeatures, aboveRank: number = 0) {
    return this.availablePlans.find(
      (x) =>
        x.planRank > aboveRank && x.features.some((x) => x.feature === feature)
    );
  }
}

export class AdminSiteOptionsPlan {
  planId: Guid;
  planName: string;
  planChipBackgroundColor: string;
  planChipTextColor: string;
  planChipLabel: string;
  planRank: number;
  features: FeatureTrackingModel[];
  constructor(props?: Partial<AdminSiteOptionsPlan>) {
    props = props || {};
    Object.assign(this, props);
    this.planId = Guid.valueOrNew(props.planId);
    this.features = (props.features || []).map(
      (x) => new FeatureTrackingModel(x)
    );
    this.planRank = asNumber(props.planRank);
    if (!this.planChipBackgroundColor)
      this.planChipBackgroundColor =
        PricePlanProperties.DefaultChipBackgroundColor;
    if (!this.planChipTextColor)
      this.planChipTextColor = PricePlanProperties.DefaultChipTextColor;
    if (!this.planChipLabel) this.planChipLabel = this.planName;
  }

  hasAccessToFeature(
    feature: PriceFeatures,
    id?: Guid
  ): HasFeatureAccessResponse {
    let response: HasFeatureAccessResponse = {
      hasAccess: false,
      idUsed: false
    };

    for (const x of this.features) {
      response = x.hasAccessToFeature(feature, id);
      if (response.hasAccess) {
        break;
      }
    }

    return response;
  }
}
