import React, { forwardRef } from 'react';

import { IconProps } from './IconProps';
import { getDefaultColor, IconSvg } from './IconSvg';

export const LinkedInCardIcon = forwardRef<HTMLSpanElement, IconProps>(
  ({ color, ...rest }, ref) => {
    return (
      <IconSvg ref={ref} {...rest}>
        <path
          d="M19.0411 19.0408H16.0777V14.4C16.0777 13.2934 16.0579 11.8688 14.5364 11.8688C12.993 11.8688 12.7569 13.0745 12.7569 14.3194V19.0405H9.79349V9.49716H12.6383V10.8013H12.6781C12.9628 10.3146 13.3742 9.91412 13.8685 9.64265C14.3628 9.37118 14.9215 9.23886 15.485 9.25978C18.4885 9.25978 19.0423 11.2354 19.0423 13.8055L19.0411 19.0408ZM6.44982 8.19265C6.10969 8.19271 5.77719 8.09192 5.49436 7.90301C5.21152 7.7141 4.99106 7.44556 4.86085 7.13135C4.73063 6.81714 4.69651 6.47138 4.76281 6.13778C4.8291 5.80418 4.99283 5.49773 5.23329 5.25719C5.47375 5.01664 5.78013 4.85281 6.11371 4.78639C6.44728 4.71998 6.79306 4.75397 7.10732 4.88407C7.42157 5.01417 7.69019 5.23454 7.8792 5.51731C8.06821 5.80008 8.16913 6.13254 8.16919 6.47266C8.16923 6.69849 8.12479 6.92212 8.03841 7.13078C7.95202 7.33944 7.82538 7.52904 7.66572 7.68875C7.50607 7.84847 7.31651 7.97517 7.10789 8.06163C6.89926 8.14809 6.67565 8.19261 6.44982 8.19265ZM7.9315 19.0408H4.96504V9.49716H7.9315V19.0408ZM20.5184 2.00136H3.47595C3.08913 1.997 2.7164 2.14638 2.43967 2.41669C2.16294 2.68699 2.00484 3.05611 2.00012 3.44292V20.5561C2.00468 20.9431 2.16268 21.3125 2.4394 21.5831C2.71612 21.8536 3.08894 22.0033 3.47595 21.9992H20.5184C20.9062 22.0041 21.2801 21.8548 21.5579 21.5842C21.8357 21.3137 21.9948 20.9439 22.0001 20.5561V3.44169C21.9946 3.05411 21.8354 2.68457 21.5576 2.41427C21.2798 2.14397 20.906 1.99502 20.5184 2.00013V2.00136Z"
          fill={color || getDefaultColor()}
        />
      </IconSvg>
    );
  }
);
