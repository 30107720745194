import {
  SchemaDefinition,
  SchemaItemDataTypes,
  SchemaItemDefinition
} from '@komo-tech/core/models/SchemaDefinition';

import { FieldTypes } from '@/common/models/form/FormFieldTypes';

import { ContactFilterOptionModel } from './ContactFilterOptionModel';

export interface ContactPropertyOptionModel extends ContactFilterOptionModel {
  fieldName: string;
  fieldLabel?: string;
  fieldType: FieldTypes;
}

export const resolveContactSchemaFromOptions = (
  options: ContactPropertyOptionModel[]
) => {
  return new SchemaDefinition({
    name: 'contact',
    description: 'Contact',
    items: (options || []).map(
      (p) =>
        new SchemaItemDefinition({
          id: p.value,
          name: p.fieldName,
          dataType: fieldToSchema(p.fieldType)
        })
    )
  });
};

const fieldToSchema = (type: FieldTypes) => {
  switch (type) {
    case FieldTypes.DateTime:
    case FieldTypes.Birthday:
      return SchemaItemDataTypes.Date;
    case FieldTypes.BirthYear:
    case FieldTypes.Number:
    case FieldTypes.Currency:
      return SchemaItemDataTypes.Numeric;
    default:
      return SchemaItemDataTypes.String;
  }
};
