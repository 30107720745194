import { Guid } from '@komo-tech/core/models/Guid';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { AdminBillingSubscription } from '@/common/models/billing/subscriptions/Admin/AdminBillingSubscription';

import { AdminBillingSubscriptionActions } from '../_actions';
import { AdminBillingSubscriptionCacheKeys } from '../_cacheKeys';

export const useAdminBillingSubscriptionGet = (
  id: Guid | string,
  options: Partial<UseQueryOptions<AdminBillingSubscription>> = {}
) =>
  useQuery(
    AdminBillingSubscriptionCacheKeys.get(id),
    () => AdminBillingSubscriptionActions.getAsync(id),
    {
      refetchOnWindowFocus: false,
      ...options
    }
  );
