import {
  AsyncActionHandler,
  useActionHandler,
  UseHandleActionOptions
} from '@komo-tech/ui/hooks/useActionHandler';
import { QueryClient, useQueryClient } from '@tanstack/react-query';

import { AdminBillingSubscriptionCacheKeys } from '@/admin/data/Billing/Subscriptions/_cacheKeys';
import { AdminWorkspace } from '@/common/models/workspaces/Admin/AdminWorkspace';

import { AdminWorkspaceActions } from '../_actions';
import { AdminWorkspaceCacheKeys } from '../_cacheKeys';
import { AdminWorkspaceCreateRequest } from '../_types';

type HandleOverrides = UseHandleActionOptions<AdminWorkspace>;
interface HandleConfig extends HandleOverrides {
  handler: AsyncActionHandler;
  queryClient: QueryClient;
}

const useControlled = (config: HandleConfig) => handleCreateAsync(config);

const useUncontrolled = (options?: HandleOverrides) => {
  const [handler, { isHandling }] = useActionHandler();
  const queryClient = useQueryClient();
  const controlledOptions = (options || {}) as any as HandleConfig;
  controlledOptions.queryClient = queryClient;
  controlledOptions.handler = handler;
  const handleAsync = useControlled(controlledOptions);

  return [handleAsync, isHandling] as const;
};

const handleCreateAsync = ({
  handler,
  queryClient,
  source = 'WORKSPACE_CREATE',
  onSuccess: configOnSuccess,
  ...configRest
}: HandleConfig) => {
  return (
    request: AdminWorkspaceCreateRequest,
    options?: Pick<HandleOverrides, 'onSuccess'>
  ) =>
    handler(() => AdminWorkspaceActions.createAsync(request), {
      onSuccess: (workspace) => {
        queryClient.invalidateQueries({
          queryKey: AdminWorkspaceCacheKeys.searchWorkspaces(),
          type: 'all'
        });
        queryClient.invalidateQueries(
          AdminBillingSubscriptionCacheKeys.baseUsage
        );
        configOnSuccess?.(workspace);
        options?.onSuccess?.(workspace);
      },
      source,
      ...configRest
    });
};

export const useAdminWorkspaceCreate = {
  uncontrolled: useUncontrolled,
  controlled: useControlled
};
