import { forwardRef } from 'react';

import { IconProps } from './IconProps';
import { getDefaultColor, IconSvg } from './IconSvg';

export const PersonalityQuizIcon = forwardRef<HTMLSpanElement, IconProps>(
  ({ color, ...rest }, ref) => {
    return (
      <IconSvg {...rest} ref={ref}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.69 2.074C8.07 1.046 10.051.56 12.536.56c3.743 0 7.825 2.64 8.304 7.12.302 2.832-.574 5.115-2.184 6.859.002.12.016.29.043.514.065.523.193 1.21.341 1.951.067.332.148.719.226 1.098.08.386.159.763.22 1.066.127.638.24 1.254.261 1.637a.998.998 0 0 1-.771 1.024l-8.737 2.056a1.005 1.005 0 0 1-1.236-.978v-2.905c-.358.082-.762.16-1.153.206-.426.05-.939.076-1.381-.031a1.614 1.614 0 0 1-.748-.391 1.31 1.31 0 0 1-.401-.956c0-.181-.07-1.076-.157-2.102-.055-.652-.114-1.324-.16-1.828h-.852c-.67 0-1.2-.668-.942-1.356.257-.687 1.3-3.482 1.575-4.3l-.147-2.16A5.535 5.535 0 0 1 4.6 6.5c0-1.818.665-3.365 2.09-4.426Zm-.258 4.863c.018.156.044.33.072.522l.058.409c.037.272.073.568.084.848.01.267.002.594-.09.9-.132.437-.8 2.246-1.26 3.484h.34a1.11 1.11 0 0 1 1.105 1.01c.038.42.132 1.466.217 2.466.06.703.118 1.425.146 1.875.133.004.312-.004.536-.03.722-.086 1.547-.316 1.87-.413a1.006 1.006 0 0 1 1.294.965v2.93l7.055-1.66a33.104 33.104 0 0 0-.138-.722c-.07-.355-.14-.692-.214-1.041-.073-.35-.148-.714-.23-1.121a30.209 30.209 0 0 1-.363-2.085 6.105 6.105 0 0 1-.056-.831c.002-.123.012-.266.043-.408.026-.12.093-.363.295-.57 1.356-1.393 2.11-3.21 1.855-5.593-.36-3.371-3.48-5.512-6.514-5.512-2.293 0-3.83.456-4.773 1.158-.893.665-1.357 1.628-1.364 2.952l.032.467Z"
          fill={color || getDefaultColor()}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.617 6.902a1.412 1.412 0 0 0-1.57.923 1.21 1.21 0 1 1-2.284-.803 3.833 3.833 0 0 1 7.449 1.276c0 1.517-1.122 2.488-1.85 2.974a7.366 7.366 0 0 1-1.548.779l-.034.012-.011.003-.004.002h-.002s-.002 0-.375-1.119l.373 1.12a1.21 1.21 0 0 1-.769-2.296l.01-.003.058-.022a4.952 4.952 0 0 0 .958-.49c.583-.389.773-.729.773-.96v-.002a1.412 1.412 0 0 0-1.174-1.394ZM11.238 14.418c0-.669.542-1.211 1.21-1.211h.01a1.21 1.21 0 0 1 0 2.421h-.01a1.21 1.21 0 0 1-1.21-1.21Z"
          fill={color || getDefaultColor()}
        />
      </IconSvg>
    );
  }
);
