import React, { forwardRef } from 'react';

import { IconProps } from './IconProps';
import { getDefaultColor, IconSvg } from './IconSvg';

export const CountdownIcon = forwardRef<HTMLSpanElement, IconProps>(
  ({ color, ...rest }, ref) => {
    return (
      <IconSvg ref={ref} {...rest}>
        <path
          d="M18.37 2.3L20.9 4.4a.93.93 0 001.32-.12.94.94 0 00-.12-1.32L19.57.85a.94.94 0 10-1.2 1.44zM2.49 4.63c.2 0 .42-.07.6-.22L5.63 2.3A.94.94 0 004.43.85L1.9 2.97a.94.94 0 00.6 1.66zM19.82 5.12a10.98 10.98 0 00-6.88-3.2V.93a.94.94 0 10-1.88 0v.97c-2.6.22-5.02 1.34-6.88 3.2a10.99 10.99 0 00-3.24 7.83c0 2.95 1.15 5.73 3.24 7.82A10.99 10.99 0 0012 24c2.38 0 4.65-.74 6.56-2.15a.94.94 0 10-1.12-1.51A9.2 9.2 0 1112 3.75a9.2 9.2 0 018.05 13.61.94.94 0 101.65.9 11.08 11.08 0 00-1.88-13.14z"
          fill={color || getDefaultColor()}
        />
        <path
          d="M16.57 7.3a.94.94 0 00-1.32.13l-2.96 3.66a1.87 1.87 0 101.46 1.18l2.96-3.66c.32-.4.26-1-.14-1.32z"
          fill={color || getDefaultColor()}
        />
      </IconSvg>
    );
  }
);
