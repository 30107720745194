import { forwardRef } from 'react';

import { IconProps, tablerIconClasses } from './IconProps';
import { IconSvg } from './IconSvg';

export const MemoryChallengeIcon = forwardRef<HTMLSpanElement, IconProps>(
  ({ className, ...rest }, ref) => {
    return (
      <IconSvg className={tablerIconClasses(className)} ref={ref} {...rest}>
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M14 4h6v6h-6z" />
        <path d="M4 14h6v6h-6z" />
        <path d="M17 17m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
        <path d="M7 7m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
      </IconSvg>
    );
  }
);
