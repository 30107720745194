import { Guid } from '@komo-tech/core/models/Guid';

import { AdministratorLoginTypes } from './AdministratorLoginTypes';

export class InviteDetails {
  id: Guid;
  name: string;
  email: string;
  signUpTypes: AdministratorLoginTypes[];

  constructor(props?: Partial<InviteDetails>) {
    props = props || {};
    Object.assign(this, props);
    this.id = props.id ? new Guid(props.id) : Guid.empty();
  }
}
