import React, { forwardRef } from 'react';

import { IconProps } from './IconProps';
import { getDefaultColor, IconSvg } from './IconSvg';

export const SecretCodeIcon = forwardRef<HTMLSpanElement, IconProps>(
  ({ color, ...rest }, ref) => {
    return (
      <IconSvg ref={ref} {...rest}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.6854 13.2686C16.0434 12.9106 16.6238 12.9106 16.9818 13.2686L16.6282 13.6222L16.9818 13.2686C17.3397 13.6266 17.3397 14.207 16.9818 14.565L12.3984 19.1483C12.0404 19.5063 11.46 19.5063 11.1021 19.1483L9.01873 17.065C8.66075 16.707 8.66075 16.1266 9.01873 15.7686C9.37671 15.4106 9.95711 15.4106 10.3151 15.7686L11.7502 17.2038L15.6854 13.2686ZM16.3924 13.9758L16.3925 13.9757L16.3924 13.9758Z"
          fill={color || getDefaultColor()}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.00004 7C9.021 4.80885 10.8039 3 13 3C14.8675 3 16.4361 4.27976 16.8766 6.01012C17.0129 6.54533 17.4478 7 18 7C18.5523 7 19.0088 6.54892 18.9178 6.00419C18.4434 3.16441 15.9745 1 13 1C9.69936 1 7.02105 3.70427 7.00004 7V10H6C4.34315 10 3 11.3431 3 13V20C3 21.6569 4.34315 23 6 23H20C21.6569 23 23 21.6569 23 20V13C23 11.3431 21.6569 10 20 10H9.00004L9.00004 9.96688C9.00002 9.64033 8.99992 7.01222 9.00004 7ZM5 13C5 12.4477 5.44772 12 6 12H20C20.5523 12 21 12.4477 21 13V20C21 20.5523 20.5523 21 20 21H6C5.44772 21 5 20.5523 5 20V13Z"
          fill={color || getDefaultColor()}
        />
      </IconSvg>
    );
  }
);
