import React, { forwardRef } from 'react';

import { IconProps } from './IconProps';
import { getDefaultColor, IconSvg } from './IconSvg';

export const FacebookWatchVideoIcon = forwardRef<HTMLSpanElement, IconProps>(
  ({ color, ...rest }, ref) => {
    return (
      <IconSvg ref={ref} {...rest}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.292 18.8338H4.70846C3.2148 18.8338 2 17.6187 2 16.1252V5.70831C2 4.21523 3.2148 3 4.70846 3H19.2917C20.7852 3 22 4.2148 22 5.70831V16.1252C22 17.6184 20.7852 18.8338 19.292 18.8338ZM4.70846 4.25038C3.90411 4.25038 3.24995 4.90484 3.24995 5.70875V16.1254C3.24995 16.9294 3.90411 17.5838 4.70846 17.5838H19.2917C20.096 17.5838 20.7501 16.9294 20.7501 16.1254V5.70831C20.7501 4.9044 20.096 4.24995 19.2917 4.24995H4.70846V4.25038ZM15.027 11.4535L10.4546 14.1602C10.0342 14.4096 9.5004 14.1088 9.5004 13.6238V8.20938C9.5004 7.72383 10.0342 7.42333 10.4546 7.67286L15.027 10.3797C15.4366 10.6224 15.4366 11.2108 15.027 11.4535ZM15.9019 21.3337H8.09927C7.9736 21.3337 7.85308 21.2837 7.7642 21.1949C7.67533 21.1061 7.62538 20.9855 7.62534 20.8599V20.5575C7.62533 20.4952 7.63757 20.4336 7.66138 20.3761C7.68519 20.3186 7.72009 20.2663 7.7641 20.2223C7.80811 20.1783 7.86036 20.1433 7.91787 20.1195C7.97538 20.0957 8.03702 20.0834 8.09927 20.0834H15.9021C15.9643 20.0834 16.0259 20.0957 16.0834 20.1195C16.1409 20.1433 16.1932 20.1783 16.2372 20.2223C16.2812 20.2663 16.3161 20.3186 16.3399 20.3761C16.3636 20.4336 16.3759 20.4952 16.3758 20.5575V20.8599C16.3758 20.9221 16.3636 20.9837 16.3398 21.0412C16.3159 21.0987 16.281 21.1509 16.237 21.1949C16.193 21.2389 16.1408 21.2738 16.0833 21.2976C16.0258 21.3214 15.9641 21.3337 15.9019 21.3337Z"
          fill={color || getDefaultColor()}
        />
      </IconSvg>
    );
  }
);
