import { forwardRef } from 'react';

import { IconProps } from './IconProps';
import { IconSvg } from './IconSvg';

export const WatchToWinIcon = forwardRef<HTMLSpanElement, IconProps>(
  ({ color, ...rest }, ref) => {
    return (
      <IconSvg ref={ref} {...rest}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.65 3.2h18.7A1.65 1.65 0 0 1 23 4.85v11a1.65 1.65 0 0 1-1.65 1.65H13.1v1.1h6.05a.55.55 0 0 1 .55.55v1.1a.55.55 0 0 1-.55.55H4.85a.55.55 0 0 1-.55-.55v-1.1a.55.55 0 0 1 .55-.55h6.05v-1.1H2.65A1.65 1.65 0 0 1 1 15.85v-11A1.65 1.65 0 0 1 2.65 3.2Zm.55 12.1h17.6V5.4H3.2v9.9Zm4.802-4.9a7.739 7.739 0 0 0 .966 1.282c.745.794 1.762 1.505 3.005 1.505 1.242 0 2.259-.711 3.003-1.505a7.737 7.737 0 0 0 .967-1.282 7.736 7.736 0 0 0-.966-1.282c-.745-.794-1.762-1.505-3.005-1.505-1.242 0-2.26.711-3.003 1.505a7.738 7.738 0 0 0-.967 1.282Zm8.636 0 .543-.272-.543.272Zm-9.874-.271a.607.607 0 0 0 0 .542l.001.002.002.004.005.01.018.035.067.121a8.952 8.952 0 0 0 1.227 1.668c.846.903 2.162 1.889 3.889 1.889 1.726 0 3.042-.986 3.888-1.888a8.956 8.956 0 0 0 1.294-1.79l.018-.035.005-.01.002-.004c0-.001.001-.002-.54-.273l.541.271a.607.607 0 0 0 0-.543l-.001-.001-.002-.004-.005-.01-.018-.035a8.01 8.01 0 0 0-.318-.537 8.956 8.956 0 0 0-.976-1.253C15.015 7.386 13.7 6.4 11.973 6.4c-1.726 0-3.042.986-3.888 1.888a8.951 8.951 0 0 0-1.294 1.79l-.018.035-.005.01-.002.004c0 .001-.001.002.542.273l-.543-.271Zm.543.271-.475.237-.068.034.543-.271Zm4.666-.666a.666.666 0 1 0 0 1.332.666.666 0 0 0 0-1.332Zm-1.88.666a1.88 1.88 0 1 1 3.759 0 1.88 1.88 0 0 1-3.758 0Z"
          fill={color || 'currentColor'}
        />
      </IconSvg>
    );
  }
);
