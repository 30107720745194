import React, { forwardRef } from 'react';

import { useTheme } from '../../theme/useTheme';
import { IconProps } from './IconProps';
import { getDefaultColor, IconSvg } from './IconSvg';

export const MatterportCardIcon = forwardRef<HTMLSpanElement, IconProps>(
  ({ color, ...rest }, ref) => {
    const theme = useTheme();
    return (
      <IconSvg ref={ref} {...rest}>
        <path
          d="M19.131 2L11.9946 3.99999L4.85259 2L2.00024 2.80222V21.2199L4.85816 22.0221L12.0002 20.0222L19.1422 22.0221L22.0001 21.2199V2.80222L19.131 2ZM16.2787 15.7436L11.9946 14.5459L7.71051 15.7436V8.27851L11.9946 9.47627L16.2787 8.27851V15.7436Z"
          fill={theme.colors.gray[6]}
        />
        <path
          d="M11.9944 5.59798V9.47539L4.85235 7.47541V22.0157L2 21.219V2.80135L11.9944 5.59798Z"
          fill={color || getDefaultColor()}
        />
        <path
          d="M16.2785 17.3425L11.9944 16.1392V20.0166L19.1308 22.0166V7.47629L16.2785 8.27851V17.3425Z"
          fill={color || getDefaultColor()}
        />
        <path
          d="M19.131 2L21.989 2.80222L11.9946 5.59886L2.00024 2.80222L4.85259 2L11.9946 3.99999L19.131 2Z"
          fill={theme.colors.gray[5]}
        />
        <path
          d="M11.9945 14.5403L16.2786 15.7436V17.3425L11.9945 16.1392L7.71045 17.3425V15.7436L11.9945 14.5403Z"
          fill={theme.colors.gray[5]}
        />
      </IconSvg>
    );
  }
);
