import { MarketingAnswer } from './MarketingAnswer';

export enum OnboardingMarketingAnswersResultCodes {
  Unknown = 'Unknown',

  Success = 'Success'
}

export interface OnboardingMarketingAnswerStateList {
  [prop: string]: MarketingAnswer;
}

export class OnboardingMarketingAnswersResult {
  code: OnboardingMarketingAnswersResultCodes =
    OnboardingMarketingAnswersResultCodes.Unknown;

  onboardingQuestionState: OnboardingMarketingAnswerStateList;

  constructor(props?: Partial<OnboardingMarketingAnswersResult>) {
    props = props || {};
    Object.assign(this, props);
  }
}
