import { Guid } from '@komo-tech/core/models/Guid';
import { OperationResult } from '@komo-tech/core/models/OperationResult';
import { QueryParams } from '@komo-tech/core/models/QueryParams';
import { LocalStorageHelper } from '@komo-tech/core/utils/localStorage';

import { AdministratorLoginTypes } from '@/admin/models/AdministratorLoginTypes';
import {
  AdministratorSession,
  SessionResponseCode
} from '@/admin/models/AdministratorSession';
import { InviteDetails } from '@/admin/models/InviteDetails';
import { CompleteOnboardingApiResponse } from '@/admin/models/onboarding/CompleteOnboardingApiResponse';
import { OnboardingMarketingAnswersResult } from '@/admin/models/onboarding/OnboardingMarketingAnswersResult';
import { OnboardStartResponse } from '@/admin/models/onboarding/OnboardStartResponse';
import { ApiLocalKeys } from '@/common/data/ApiLocalKeys';
import { DataDogService } from '@/common/utils/DataDogService';

import { AdminLiveApi } from '../AdminApi';
import { AdminCache } from '../AdminCache';
import {
  AddLoginTypeApiRequest,
  AdministratorInviteAccept,
  AdministratorInviteCreate,
  AdministratorLoginRequest,
  CompleteOnboardingApiRequest,
  OAuthVerifyRequest,
  SetMarketingAnswerApiRequest,
  StartOnboardingApiRequest
} from './_types';

const assignSessionCache = (session: AdministratorSession) => {
  AdminCache.setAdministratorSession(session.id);
  AdminCache.setCompany(session.companyId);

  DataDogService.setAdministrator({
    id: session.administratorId.toString(),
    name: session.name,
    email: session.email,
    adminType: session.type
  });
};

export const AdminAuthActions = {
  loginAsync: async (req: AdministratorLoginRequest) =>
    new AdministratorSession(await AdminLiveApi.postAsync('auth/login', req)),

  loginViaGoogleAuth: async (req: OAuthVerifyRequest) => {
    return new OperationResult<SessionResponseCode, AdministratorSession>(
      await AdminLiveApi.postAsync('auth/login/google', req)
    );
  },

  loginViaMicrosoftAuthAsync: async (req: OAuthVerifyRequest) => {
    return new OperationResult<SessionResponseCode, AdministratorSession>(
      await AdminLiveApi.postAsync('auth/login/azure', req)
    );
  },

  logoutAsync: async () => await AdminLiveApi.postAsync('auth/logout'),

  checkValidSessionAsync: async () =>
    new OperationResult(await AdminLiveApi.getAsync('auth/session')),

  updateSessionCompanyAsync: async (companyId: Guid) =>
    Guid.valueOrUndefined(
      await AdminLiveApi.putAsync('administrators/company', { companyId })
    ),

  tryReauthorizeSessionAsync: async () =>
    new OperationResult(await AdminLiveApi.getAsync('authorize/session')),

  requestResetPasswordAsync: (email: string) =>
    AdminLiveApi.postAsync('administrators/reset-request', {
      email
    }),

  addLoginTypeAsync: (request: AddLoginTypeApiRequest) =>
    AdminLiveApi.postAsync('auth/add-login-type', request),

  removeLoginTypeAsync: (loginType: AdministratorLoginTypes) =>
    AdminLiveApi.deleteAsync(
      `auth/remove-login-type${new QueryParams({ loginType }).toString()}`
    ),

  passwordUpdateAsync: (code: string, password: string) =>
    AdminLiveApi.postAsync(`administrators/reset-request-finish`, {
      password,
      code: code.toString()
    }),

  loginRedirectAndSession: async (
    session: AdministratorSession,
    onRouterPush: (url: string) => void,
    redirectUrl?: string
  ) => {
    assignSessionCache(session);
    if (redirectUrl) {
      return onRouterPush(redirectUrl);
    }
    const [workspaceSuccess, workspaceId] = LocalStorageHelper.tryGetGuid(
      ApiLocalKeys.Workspace
    );
    if (workspaceSuccess) {
      return onRouterPush(`/portal/workspaces/${workspaceId}/hubs`);
    }
    return onRouterPush('/portal/workspaces');
  },

  onboarding: {
    startAsync: async (request: StartOnboardingApiRequest) =>
      new OnboardStartResponse(
        await AdminLiveApi.postAsync(`onboarding/start`, request)
      ),

    setMarketingAnswerAsync: async ({
      adminId,
      questionId,
      answer
    }: SetMarketingAnswerApiRequest) =>
      new OnboardingMarketingAnswersResult(
        await AdminLiveApi.postAsync(`onboarding/answer/${adminId}`, {
          questionId,
          ...answer
        })
      ),

    completeAsync: async (request: CompleteOnboardingApiRequest) =>
      new CompleteOnboardingApiResponse(
        await AdminLiveApi.postAsync(`onboarding/complete`, request)
      ),

    sendVerificationAsync: (email: string) =>
      AdminLiveApi.postAsync(`onboarding/verify`, { email }),
    getQuestionStateAsync: async (adminId: Guid) =>
      new OnboardingMarketingAnswersResult(
        await AdminLiveApi.getAsync(
          `onboarding/questions-state${new QueryParams({ adminId }).toString()}`
        )
      )
  },

  invites: {
    acceptInviteAsync: (accept: AdministratorInviteAccept) =>
      AdminLiveApi.postAsync('administrators/accept', accept),

    /**
     * not used?
     */
    createInviteAsync: (invite: AdministratorInviteCreate) =>
      AdminLiveApi.postAsync(`administrators/invite`, invite),

    getInviteAsync: async (inviteId: Guid) =>
      new InviteDetails(
        await AdminLiveApi.getAsync(`administrators/invite/${inviteId}`)
      ),

    /**
     * not used?
     */
    revokeInviteAsync: (inviteId: Guid) =>
      AdminLiveApi.deleteAsync(`administrators/revoke/${inviteId}`),

    /**
     * not used?
     */
    getInvitesAsync: () =>
      AdminLiveApi.getAsync('administrators/invites?pageSize=1000')
  }
};
