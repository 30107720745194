import React, { forwardRef } from 'react';

import { IconProps } from './IconProps';
import { IconSvg } from './IconSvg';

export const TwitterCardIcon = forwardRef<HTMLSpanElement, IconProps>(
  ({ color, ...rest }, ref) => {
    return (
      <IconSvg ref={ref} {...rest}>
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path
          stroke={color || 'currentColor'}
          fill={color || 'currentColor'}
          d="M4 4l11.733 16h4.267l-11.733 -16z"
        />
        <path
          stroke={color || 'currentColor'}
          d="M4 20l6.768 -6.768m2.46 -2.46l6.772 -6.772"
        />
      </IconSvg>
    );
  }
);
