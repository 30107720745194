import React, { forwardRef } from 'react';

import { IconProps, tablerIconClasses } from './IconProps';
import { IconSvg } from './IconSvg';

export const HtmlIcon = forwardRef<HTMLSpanElement, IconProps>(
  ({ className, ...rest }, ref) => {
    return (
      <IconSvg className={tablerIconClasses(className)} ref={ref} {...rest}>
        <path d="M10 9.5 8 12l2 2.5" />
        <path d="m14 9.5 2 2.5-2 2.5" />
        <rect width="18" height="18" x="3" y="3" rx="2" />
      </IconSvg>
    );
  }
);
