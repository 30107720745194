import { forwardRef } from 'react';

import { IconProps } from './IconProps';
import { IconSvg } from './IconSvg';

export const VimeoIcon = forwardRef<HTMLSpanElement, IconProps>(
  ({ color, ...rest }, ref) => {
    return (
      <IconSvg {...rest} ref={ref}>
        <path
          d="M21.9913 7.31185C21.9034 9.26033 20.5446 11.9298 17.9151 15.3204C15.1896 18.8588 12.8866 20.6287 11.0061 20.6301C9.84126 20.6301 8.85569 19.5519 8.04938 17.3955L6.43608 11.468C5.83634 9.31298 5.19395 8.23478 4.50891 8.23345C4.35964 8.23345 3.8372 8.54798 2.94159 9.17704L2 7.96356C2.98757 7.09594 3.95982 6.22965 4.91673 5.36469C6.23483 4.22518 7.2224 3.62544 7.87945 3.56547C9.43477 3.4162 10.3924 4.48241 10.7522 6.76408C11.14 9.22435 11.4066 10.757 11.5519 11.3621C12.0023 13.4039 12.4961 14.4241 13.0332 14.4228C13.453 14.4228 14.0814 13.7604 14.9184 12.4356C15.7554 11.1109 16.2038 10.1026 16.2638 9.41093C16.3838 8.26943 15.936 7.69568 14.9184 7.69568C14.4081 7.703 13.9047 7.81457 13.439 8.02354C14.4266 4.78761 16.3058 3.21429 19.0766 3.30358C21.1317 3.36622 22.1033 4.70231 21.9913 7.31185Z"
          fill={color || 'currentColor'}
        />
      </IconSvg>
    );
  }
);
