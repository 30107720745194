import React, { forwardRef } from 'react';

import { IconProps } from './IconProps';
import { getDefaultColor, IconSvg } from './IconSvg';

export const ClickBannerIcon = forwardRef<HTMLSpanElement, IconProps>(
  ({ color, ...rest }, ref) => {
    return (
      <IconSvg ref={ref} {...rest}>
        <path
          d="M11.786 20.9188L11.786 20.9205C11.7887 21.0867 11.8566 21.2452 11.9751 21.3619C12.0936 21.4785 12.2532 21.5438 12.4194 21.5438C12.5851 21.5438 12.7441 21.4779 12.8613 21.3607C12.9785 21.2435 13.0444 21.0846 13.0444 20.9188V10.5629C13.0544 10.476 13.0463 10.3878 13.0206 10.3041C12.9942 10.2182 12.9498 10.1388 12.8904 10.0714C12.8309 10.0039 12.7578 9.94993 12.6759 9.91293C12.594 9.87592 12.5051 9.85679 12.4152 9.85679C12.3253 9.85679 12.2364 9.87592 12.1545 9.91293C12.0726 9.94993 11.9995 10.0039 11.94 10.0714C11.8806 10.1388 11.8362 10.2182 11.8098 10.3041C11.784 10.3878 11.776 10.476 11.786 10.5629L11.786 20.9188Z"
          fill={color || getDefaultColor()}
          stroke={color || getDefaultColor()}
          strokeWidth="0.209524"
        />
        <path
          d="M14.9365 19.606L14.9381 19.606C15.1044 19.6033 15.2629 19.5355 15.3795 19.417L15.3048 19.3435L15.3795 19.417C15.4961 19.2985 15.5615 19.1389 15.5615 18.9726V10.5885C15.5715 10.5015 15.5634 10.4134 15.5377 10.3297C15.5113 10.2437 15.4669 10.1644 15.4075 10.097C15.348 10.0295 15.2749 9.9755 15.193 9.9385C15.1111 9.9015 15.0222 9.88236 14.9323 9.88236C14.8424 9.88236 14.7535 9.9015 14.6716 9.9385C14.5897 9.9755 14.5165 10.0295 14.4571 10.097C14.3977 10.1644 14.3533 10.2437 14.3269 10.3297C14.3011 10.4134 14.2931 10.5015 14.3031 10.5885V18.9726C14.3031 19.1406 14.3698 19.3017 14.4886 19.4205C14.6074 19.5393 14.7685 19.606 14.9365 19.606Z"
          fill={color || getDefaultColor()}
          stroke={color || getDefaultColor()}
          strokeWidth="0.209524"
        />
        <path
          d="M17.4436 19.6058L17.4436 19.6059H17.4453C17.6133 19.6059 17.7744 19.5391 17.8932 19.4203C18.0119 19.3016 18.0787 19.1405 18.0787 18.9725V15.5135C18.0887 15.4266 18.0806 15.3384 18.0549 15.2547C18.0285 15.1688 17.9841 15.0895 17.9247 15.022C17.8652 14.9546 17.7921 14.9006 17.7102 14.8635C17.6283 14.8265 17.5394 14.8074 17.4495 14.8074C17.3596 14.8074 17.2707 14.8265 17.1888 14.8635C17.1069 14.9006 17.0338 14.9546 16.9743 15.022C16.9149 15.0895 16.8705 15.1688 16.8441 15.2547C16.8183 15.3385 16.8103 15.4266 16.8203 15.5135V18.9725C16.8203 18.9725 16.8203 18.9725 16.8203 18.9725C16.8203 19.1387 16.8857 19.2983 17.0023 19.4168C17.1189 19.5353 17.2774 19.6032 17.4436 19.6058Z"
          fill={color || getDefaultColor()}
          stroke={color || getDefaultColor()}
          strokeWidth="0.209524"
        />
        <path
          d="M19.9607 19.6054L19.9607 19.6054H19.9623C20.1303 19.6054 20.2914 19.5386 20.4102 19.4199C20.529 19.3011 20.5957 19.14 20.5957 18.972V16.3626H20.5965L20.5949 16.3494C20.5755 16.1965 20.501 16.0558 20.3853 15.9539C20.2696 15.8519 20.1207 15.7957 19.9665 15.7957C19.8124 15.7957 19.6635 15.8519 19.5478 15.9539C19.4321 16.0558 19.3576 16.1965 19.3382 16.3494L19.3374 16.3493V16.3626V18.972C19.3374 18.972 19.3374 18.972 19.3374 18.972C19.3374 19.1383 19.4027 19.2978 19.5193 19.4163C19.6359 19.5348 19.7945 19.6027 19.9607 19.6054Z"
          fill={color || getDefaultColor()}
          stroke={color || getDefaultColor()}
          strokeWidth="0.209524"
        />
        <path
          d="M22.4795 23.1301C22.5633 23.1301 22.6462 23.1133 22.7234 23.0807C22.8006 23.048 22.8704 23.0002 22.9287 22.94L22.8534 22.8671L22.9287 22.94C22.987 22.8798 23.0326 22.8085 23.0628 22.7303C23.0928 22.6527 23.1069 22.5699 23.1045 22.4867V17.0429H23.1053L23.1037 17.0298C23.0843 16.8768 23.0098 16.7362 22.8941 16.6342C22.7784 16.5323 22.6295 16.476 22.4753 16.476C22.3211 16.476 22.1723 16.5323 22.0566 16.6342C21.9409 16.7362 21.8664 16.8768 21.847 17.0298L21.8462 17.0297V17.0429V22.5051H21.8462L21.8462 22.5068C21.8488 22.673 21.9167 22.8316 22.0352 22.9482C22.1537 23.0648 22.3132 23.1301 22.4795 23.1301ZM22.4795 23.1301L22.4795 23.0253L22.4795 23.1301C22.4795 23.1301 22.4795 23.1301 22.4795 23.1301Z"
          fill={color || getDefaultColor()}
          stroke={color || getDefaultColor()}
          strokeWidth="0.209524"
        />
        <path
          d="M9.83515 23.1303V23.1305L9.84179 23.1301C10.001 23.12 10.1505 23.0502 10.2605 22.9346C10.3704 22.8191 10.4327 22.6663 10.4349 22.5068L10.4349 22.5068V22.5053V19.2859L12.8005 17.5385L12.8005 17.5386L12.8019 17.5375C12.9348 17.4358 13.022 17.2858 13.0446 17.12C13.0672 16.9544 13.0234 16.7866 12.9228 16.6532C12.8733 16.5863 12.8108 16.53 12.7392 16.4875C12.6674 16.445 12.5878 16.4173 12.5051 16.406C12.4225 16.3948 12.3384 16.4002 12.2578 16.4219C12.1776 16.4436 12.1025 16.481 12.0369 16.5321L9.46171 18.4697L9.46151 18.4699C9.38376 18.5287 9.32063 18.6046 9.27704 18.6918C9.23345 18.779 9.21057 18.8751 9.21018 18.9725V18.973V22.5053C9.21018 22.6711 9.27602 22.83 9.39323 22.9473C9.51043 23.0645 9.6694 23.1303 9.83515 23.1303Z"
          fill={color || getDefaultColor()}
          stroke={color || getDefaultColor()}
          strokeWidth="0.209524"
        />
        <path
          d="M15.4567 10.6617H15.5546L15.5613 10.5641C15.579 10.3054 15.5434 10.0457 15.4566 9.80132C15.3698 9.55691 15.2336 9.33296 15.0566 9.14339C14.8796 8.95381 14.6655 8.80266 14.4276 8.69931C14.1897 8.59597 13.9331 8.54264 13.6738 8.54264C13.4144 8.54264 13.1578 8.59597 12.9199 8.69931C12.682 8.80266 12.4679 8.95381 12.2909 9.14339C12.1139 9.33296 11.9778 9.55691 11.891 9.80131C11.8041 10.0457 11.7685 10.3054 11.7863 10.5641L11.793 10.6617H11.8908H12.9396H13.0562L13.0438 10.5458C13.0343 10.4574 13.0435 10.368 13.0709 10.2834C13.0982 10.1988 13.1431 10.121 13.2025 10.0548C13.2619 9.98873 13.3346 9.93585 13.4158 9.89966C13.497 9.86346 13.5849 9.84475 13.6738 9.84475C13.7627 9.84475 13.8506 9.86346 13.9318 9.89966C14.0129 9.93585 14.0856 9.98873 14.145 10.0548C14.2044 10.121 14.2493 10.1988 14.2766 10.2834C14.304 10.368 14.3132 10.4574 14.3038 10.5458L14.2914 10.6617H14.4079H15.4567Z"
          fill={color || getDefaultColor()}
          stroke={color || getDefaultColor()}
          strokeWidth="0.209524"
        />
        <path
          d="M17.9738 15.6118H18.0716L18.0783 15.5143C18.0961 15.2555 18.0605 14.9959 17.9737 14.7514C17.8868 14.507 17.7507 14.2831 17.5737 14.0935C17.3967 13.9039 17.1826 13.7528 16.9447 13.6494C16.7068 13.5461 16.4502 13.4928 16.1909 13.4928C15.9315 13.4928 15.6749 13.5461 15.437 13.6494C15.1991 13.7528 14.985 13.9039 14.808 14.0935C14.631 14.2831 14.4949 14.507 14.408 14.7514C14.3212 14.9959 14.2856 15.2555 14.3034 15.5143L14.3101 15.6118H14.4079H15.4567H15.5733L15.5609 15.4959C15.5514 15.4075 15.5606 15.3182 15.588 15.2336C15.6153 15.149 15.6602 15.0711 15.7196 15.005C15.779 14.9389 15.8517 14.886 15.9329 14.8498C16.0141 14.8136 16.102 14.7949 16.1909 14.7949C16.2797 14.7949 16.3676 14.8136 16.4488 14.8498C16.53 14.886 16.6027 14.9389 16.6621 15.005C16.7215 15.0711 16.7664 15.149 16.7937 15.2336C16.8211 15.3182 16.8303 15.4075 16.8209 15.4959L16.8084 15.6118H16.925H17.9738Z"
          fill={color || getDefaultColor()}
          stroke={color || getDefaultColor()}
          strokeWidth="0.209524"
        />
        <path
          d="M20.491 16.4679H20.6032L20.5955 16.356C20.5627 15.8778 20.3496 15.4298 19.9992 15.1027C19.6488 14.7756 19.1874 14.5936 18.708 14.5936C18.2287 14.5936 17.7672 14.7756 17.4168 15.1027C17.0665 15.4298 16.8534 15.8778 16.8205 16.356L16.8128 16.4679H16.925H17.9739H18.068L18.078 16.3743C18.0947 16.2188 18.1682 16.075 18.2845 15.9705C18.4008 15.866 18.5517 15.8081 18.708 15.8081C18.8644 15.8081 19.0152 15.866 19.1315 15.9705C19.2478 16.075 19.3214 16.2188 19.338 16.3743L19.348 16.4679H19.4422H20.491Z"
          fill={color || getDefaultColor()}
          stroke={color || getDefaultColor()}
          strokeWidth="0.209524"
        />
        <path
          d="M22.9997 17.2397H23.1045V17.135C23.1045 16.6354 22.906 16.1563 22.5528 15.8031C22.1995 15.4499 21.7205 15.2514 21.2209 15.2514C20.7214 15.2514 20.2423 15.4499 19.8891 15.8031C19.5358 16.1563 19.3374 16.6354 19.3374 17.135V17.2397H19.4421H20.4909H20.5957V17.135C20.5957 16.9692 20.6616 16.8101 20.7788 16.6929C20.8961 16.5756 21.0551 16.5098 21.2209 16.5098C21.3867 16.5098 21.5458 16.5756 21.663 16.6929C21.7803 16.8101 21.8461 16.9692 21.8461 17.135V17.2397H21.9509H22.9997Z"
          fill={color || getDefaultColor()}
          stroke={color || getDefaultColor()}
          strokeWidth="0.209524"
        />
        <path
          d="M13.5981 3.80267V3.69791H13.4934H3.71851H3.61374V3.80267V4.85148V4.95624H3.71851H13.4934H13.5981V4.85148V3.80267Z"
          fill={color || getDefaultColor()}
          stroke={color || getDefaultColor()}
          strokeWidth="0.209524"
        />
        <path
          d="M9.05052 6.76447V6.6597H8.94576H3.71851H3.61374V6.76447V7.81327V7.91803H3.71851H8.94576H9.05052V7.81327V6.76447Z"
          fill={color || getDefaultColor()}
          stroke={color || getDefaultColor()}
          strokeWidth="0.209524"
        />
        <path
          d="M2.48509 2.15357H2.48511H21.5147C21.6026 2.15357 21.6869 2.1885 21.7491 2.25067C21.8113 2.31285 21.8462 2.39718 21.8462 2.48511V9.60861C21.8462 9.69654 21.8113 9.78087 21.7491 9.84304C21.6869 9.90522 21.6026 9.94015 21.5147 9.94015H18.0809C17.9939 9.93009 17.9058 9.93817 17.8221 9.9639C17.7361 9.9903 17.6568 10.0347 17.5894 10.0941C17.5219 10.1536 17.4679 10.2267 17.4309 10.3086C17.3939 10.3905 17.3748 10.4794 17.3748 10.5693C17.3748 10.6592 17.3939 10.7481 17.4309 10.83C17.4679 10.9119 17.5219 10.9851 17.5894 11.0445C17.6568 11.1039 17.7361 11.1483 17.8221 11.1747C17.9058 11.2005 17.9939 11.2085 18.0809 11.1985H21.5147C21.9363 11.1985 22.3407 11.031 22.6389 10.7328C22.937 10.4347 23.1045 10.0303 23.1045 9.60861V2.48511C23.1045 2.06345 22.937 1.65906 22.6389 1.3609C22.3407 1.06274 21.9363 0.895238 21.5147 0.895238H2.48511C2.06345 0.895238 1.65906 1.06274 1.3609 1.3609C1.06274 1.65906 0.895238 2.06345 0.895238 2.48511V9.60861C0.895238 10.0303 1.06274 10.4347 1.3609 10.7328C1.65906 11.031 2.06345 11.1985 2.48511 11.1985H9.35897C9.44594 11.2085 9.53407 11.2005 9.6178 11.1747C9.70373 11.1483 9.78306 11.1039 9.8505 11.0445C9.91795 10.9851 9.97197 10.9119 10.009 10.83C10.046 10.7481 10.0651 10.6592 10.0651 10.5693C10.0651 10.4794 10.046 10.3905 10.009 10.3086C9.97197 10.2267 9.91795 10.1536 9.8505 10.0941C9.78306 10.0347 9.70373 9.9903 9.6178 9.9639C9.53407 9.93817 9.44594 9.93009 9.35897 9.94015H2.48511V9.94013L2.4831 9.94017C2.43902 9.94102 2.39522 9.93307 2.35426 9.91679L2.31557 10.0141L2.35426 9.91679C2.3133 9.9005 2.27599 9.87622 2.24453 9.84535L2.17116 9.92013L2.24453 9.84534C2.21306 9.81447 2.18807 9.77764 2.17101 9.73699C2.15395 9.69635 2.14517 9.65271 2.14518 9.60863V9.60861V2.48617C2.14709 2.39746 2.18361 2.31301 2.24696 2.25085C2.31053 2.18849 2.39604 2.15355 2.48509 2.15357Z"
          fill={color || getDefaultColor()}
          stroke={color || getDefaultColor()}
          strokeWidth="0.209524"
        />
        <path
          d="M18.0747 7.89243C19.101 7.89243 19.933 7.06041 19.933 6.03406C19.933 5.00771 19.101 4.17569 18.0747 4.17569C17.0483 4.17569 16.2163 5.00771 16.2163 6.03406C16.2163 7.06041 17.0483 7.89243 18.0747 7.89243Z"
          fill={color || getDefaultColor()}
          stroke={color || getDefaultColor()}
          strokeWidth="0.209524"
        />
      </IconSvg>
    );
  }
);
