import React, { forwardRef } from 'react';

import { IconProps } from './IconProps';
import { IconSvg } from './IconSvg';

export const TikTokCardIcon = forwardRef<HTMLSpanElement, IconProps>(
  ({ color, ...rest }, ref) => {
    return (
      <IconSvg ref={ref} {...rest}>
        <path
          d="M20.9909 7.00778C19.8478 7.00778 18.7929 6.62911 17.946 5.99022C16.9747 5.25778 16.2767 4.18356 16.0303 2.94533C15.9689 2.63933 15.9363 2.32356 15.9329 2H12.6674V10.9231L12.6634 15.8107C12.6634 17.1173 11.8125 18.2253 10.6329 18.6149C10.2907 18.728 9.92093 18.7816 9.53581 18.7604C9.04448 18.7336 8.58404 18.5851 8.18381 18.3458C7.33204 17.8364 6.7547 16.9122 6.73892 15.8551C6.71426 14.2029 8.05004 12.856 9.70092 12.856C10.0269 12.856 10.3398 12.9091 10.6329 13.0058V10.5671V9.69044C10.3238 9.64467 10.0094 9.62089 9.69115 9.62089C7.88404 9.62089 6.19404 10.372 4.98581 11.7253C4.0727 12.748 3.52492 14.0527 3.44048 15.4209C3.32981 17.2182 3.98737 18.9267 5.26292 20.1873C5.45026 20.3724 5.64715 20.5442 5.85292 20.7027C6.94648 21.5438 8.28337 22 9.69137 22C10.0094 22 10.324 21.9764 10.6331 21.9307C11.9485 21.7358 13.162 21.1338 14.1198 20.1869C15.2967 19.0238 15.9469 17.4796 15.9538 15.836L15.9369 8.53733C16.4983 8.97044 17.1123 9.32889 17.7709 9.60689C18.7956 10.0391 19.882 10.2582 21 10.258V7.88689V7.00711C21.0009 7.00778 20.9916 7.00778 20.9909 7.00778Z"
          fill={color || 'currentColor'}
        />
      </IconSvg>
    );
  }
);
