import {
  AsyncActionHandler,
  useActionHandler,
  UseHandleActionOptions
} from '@komo-tech/ui/hooks/useActionHandler';
import { usePostHog } from 'posthog-js/react';

import { UserflowService } from '@/admin/utils/UserflowService';
import { DataDogService } from '@/common/utils/DataDogService';

import { AdminAuthActions } from '../_actions';

interface HandleOverrides extends UseHandleActionOptions {}

interface HandleConfig extends HandleOverrides {
  onRouterPush: (path: string) => void;
  handler: AsyncActionHandler;
}

const useControlled = (config: HandleConfig) => handleLogoutAsync(config);

const useUncontrolled = (
  options: Pick<HandleConfig, 'onRouterPush'> & HandleOverrides
) => {
  const [handler, { isHandling }] = useActionHandler();
  const posthog = usePostHog();

  const handleSuccess = (r: Promise<unknown>) => {
    posthog?.reset();
    DataDogService.forgetAdministrator();
    UserflowService.forgetAdmin();
    options?.onSuccess?.(r);
  };

  const handleError = (e: any) => {
    posthog?.reset();
    DataDogService.forgetAdministrator();
    UserflowService.forgetAdmin();
    options?.onError?.(e);
  };

  const controlledOptions: HandleConfig = {
    ...options,
    handler,
    onSuccess: handleSuccess,
    onError: handleError
  };

  const handleAsync = useControlled(controlledOptions);
  return [handleAsync, isHandling] as const;
};

const handleLogoutAsync = ({
  handler,
  source = 'ADMIN_LOGOUT',
  onSuccess: configOnSuccess,
  noLogOnError = true,
  noToastOnError = true,
  onRouterPush,
  onError,
  ...configRest
}: HandleConfig) => {
  return (options?: Pick<HandleOverrides, 'onSuccess'>) =>
    handler(() => AdminAuthActions.logoutAsync(), {
      onSuccess: () => {
        onRouterPush('/portal/auth/login');
        configOnSuccess?.();
        options?.onSuccess?.();
      },
      onError: (e) => {
        onRouterPush('/portal/auth/login');
        onError?.(e);
      },
      source,
      noLogOnError,
      noToastOnError,
      ...configRest
    });
};

export const useAdminLogout = {
  uncontrolled: useUncontrolled,
  controlled: useControlled
};
