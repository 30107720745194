import React, { forwardRef } from 'react';

import { IconProps } from './IconProps';
import { IconSvg } from './IconSvg';

export const TwitchIcon = forwardRef<HTMLSpanElement, IconProps>(
  ({ color, ...rest }, ref) => {
    return (
      <IconSvg ref={ref} {...rest}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17 20H13L10 23H7V20H2V6.637L5.364 2H22V15L17 20ZM20 13V4H6V16H9V19L12 16H17L20 13Z"
          fill={color || 'currentColor'}
        />
        <path
          d="M16 7H18V12H16V7ZM11 7H13V12H11V7Z"
          fill={color || 'currentColor'}
        />
      </IconSvg>
    );
  }
);
