import { useAdminWorkspaceCreate } from './_useWorkspaceCreate';
import { useWorkspaceCreatePostmarkServer } from './_useWorkspaceCreatePostmarkServer';
import { useWorkspaceDeactivate } from './_useWorkspaceDeactivate';
import { useWorkspaceDelete } from './_useWorkspaceDelete';
import { useWorkspaceGet } from './_useWorkspaceGet';
import { useWorkspaceReactivate } from './_useWorkspaceReactivate';
import { useWorkspacesSearch } from './_useWorkspacesSearch';
import { useAdminWorkspaceUpdate } from './_useWorkspaceUpdate';

export const AdminWorkspaceHooks = {
  useUpdateHandle: useAdminWorkspaceUpdate.uncontrolled,
  useCreateHandle: useAdminWorkspaceCreate.uncontrolled,
  useGet: useWorkspaceGet,
  useSearch: useWorkspacesSearch,
  useDelete: useWorkspaceDelete,
  useReactivate: useWorkspaceReactivate,
  useDeactivate: useWorkspaceDeactivate,
  useCreatePostmarkServer: useWorkspaceCreatePostmarkServer
};
