import { AuthToken } from '../AdministratorSession';

export enum OnboardingResultCodes {
  Unknown = 'Unknown',
  Success = 'Success',
  ExistingAdministrator = 'ExistingAdministrator',
  AlreadyComplete = 'AlreadyComplete'
}

export class CompleteOnboardingApiResponse {
  token: AuthToken;
  code: OnboardingResultCodes = OnboardingResultCodes.Unknown;

  constructor(props?: Partial<CompleteOnboardingApiResponse>) {
    props = props || {};
    Object.assign(this, props);
    this.token = new AuthToken(props.token);
  }
}
