import { Guid } from '@komo-tech/core/models/Guid';
import { QueryParams } from '@komo-tech/core/models/QueryParams';
import { ScheduleTime } from '@komo-tech/core/models/ScheduleTime';
import { arrayItemIfDefined } from '@komo-tech/core/utils/array';
import { QueryClient } from '@tanstack/react-query';

import { ApiOrderRequest } from '@/common/models/ApiOrderRequest';
import { BackgroundJobStatuses } from '@/common/models/BackgroundJobStatuses';
import { HubCampaignWithTrackingModel } from '@/common/models/companies/HubCampaignWithTrackingModel';
import { AdminSiteOptions } from '@/common/models/site/AdminSiteOptions';
import { PublishFeatureCheckStatusModel } from '@/common/models/site/PublishFeatureCheckStatusModel';
import { Site } from '@/common/models/site/Site';
import { SitePublishInfo } from '@/common/models/site/SitePublishInfo';
import { SiteSearchResponse } from '@/common/models/site/SiteSearchResponse';
import { SiteUnPublishInfo } from '@/common/models/site/SiteUnPublishInfo';

import { AdminLiveApi } from './AdminApi';
import { AdminSiteHooks } from './Sites/_hooks';

const DefaultPageSize = 25;
const baseKey = 'admin-sites';
const baseSearchKey = () => ['admin-sites', `${baseKey}-search`];

export interface SiteSearchQuery extends ApiOrderRequest {
  take?: number;
  skip?: number;
  name?: string;
  companyId?: string | Guid;
  workspaceId?: string | Guid;
  includeArchived?: boolean;
}

export interface CloneStatusResponse {
  status: BackgroundJobStatuses;
  error?: string;
  entity?: any;
}

export interface CheckDnsResponse {
  success: boolean;
}

export enum CloneCouponStatus {
  CloneCoupons = 'CloneCoupons',
  KeepCoupons = 'KeepCoupons',
  RemoveCoupons = 'RemoveCoupons'
}

export interface AdminCloneJobRequest {
  name: string;
  cardId?: Guid;
  toSiteId?: Guid;
  toCompanyId?: Guid;
  toWorkspaceId?: Guid;
  intendedPageId?: Guid;
  cloneCoupons: CloneCouponStatus;
}

export interface PublishSiteApiRequest {
  planId: Guid;
  creditCount: number;
}

export class AdminSiteService {
  public static hooks = AdminSiteHooks;
  public static cacheKeys = {
    base: baseKey,
    site: (siteId: Guid | string) => [baseKey, siteId?.toString()],
    siteOptions: (siteId: Guid | string) => [
      baseKey,
      `options`,
      siteId?.toString()
    ],
    baseSearch: baseSearchKey,
    search: (
      { includeArchived, companyId, workspaceId, ...rest }: SiteSearchQuery,
      queryType?: string
    ) => [
      ...baseSearchKey(),
      {
        companyId: companyId?.toString(),
        workspaceId: workspaceId?.toString(),
        archived: (includeArchived || false).toString(),
        ...rest
      },
      ...arrayItemIfDefined(queryType)
    ],
    campaign: (siteId: Guid | string) => [
      baseKey,
      `active-campaign`,
      siteId?.toString()
    ]
  };

  public static getAsync = async (siteId: Guid | string) => {
    return new Site(await AdminLiveApi.getAsync<Site>(`sites/${siteId}`));
  };

  public static getOptionsAsync = async (siteId: Guid | string) => {
    return new AdminSiteOptions(
      await AdminLiveApi.getAsync<AdminSiteOptions>(`sites/${siteId}/options`)
    );
  };

  public static searchAsync = ({
    take = DefaultPageSize,
    skip = 0,
    ...rest
  }: SiteSearchQuery) => {
    return AdminLiveApi.getPageAsync(
      `sites${new QueryParams(rest).setTakeSkip(take, skip).toString()}`,
      (s) => new SiteSearchResponse(s)
    );
  };

  public static createAsync = async (name: string, workspaceId: Guid) => {
    return new Site(
      await AdminLiveApi.postAsync<Site>('sites', { name, workspaceId })
    );
  };

  public static checkDnsAsync = async (siteId: Guid, domain: string) => {
    return await AdminLiveApi.getAsync<CheckDnsResponse>(
      `sites/${siteId}/check-dns/${domain}`
    );
  };

  public static updateAsync = async (request: Site) => {
    return new Site(
      await AdminLiveApi.putAsync<Site>(`sites/${request.id}`, request)
    );
  };

  public static setDomainAsync = async (siteId: Guid, domain: string) => {
    return new Site(
      await AdminLiveApi.putAsync<Site>(`sites/${siteId}/domains`, {
        domain
      })
    );
  };

  public static deleteAsync = async (
    siteId: Guid,
    queryClient: QueryClient
  ) => {
    await AdminLiveApi.deleteAsync(`sites/${siteId}`);
    await queryClient.invalidateQueries(
      AdminSiteService.cacheKeys.site(siteId)
    );
  };

  public static queueCloneJobAsync = async (
    siteId: Guid,
    request: AdminCloneJobRequest
  ) => {
    const jobId = await AdminLiveApi.postAsync<string>(
      `sites/${siteId}/clone-queue`,
      request
    );

    return Guid.valueOrEmpty(jobId);
  };

  public static getCloneJobStatusAsync = (jobId: Guid) => {
    return AdminLiveApi.getAsync<CloneStatusResponse>(
      `sites/clone-job/status/${jobId}`
    );
  };

  public static moveAsync = async (
    siteId: Guid,
    companyId: string,
    queryClient: QueryClient
  ) => {
    const site = new Site(
      await AdminLiveApi.postAsync<Site>(`sites/${siteId}/move`, { companyId })
    );

    await queryClient.invalidateQueries(
      AdminSiteService.cacheKeys.site(siteId)
    );
    return site;
  };

  public static resetCacheAsync = (siteId: Guid) => {
    return AdminLiveApi.postAsync(`sites/${siteId}/reset-cache`);
  };

  public static addSiteAuthAsync = async (siteId: Guid) => {
    return new Site(await AdminLiveApi.postAsync<Site>(`sites/${siteId}/auth`));
  };

  public static getPublishInfoAsync = async (siteId: Guid) => {
    return new SitePublishInfo(
      await AdminLiveApi.getAsync<SitePublishInfo>(
        `sites/${siteId}/publish-info`
      )
    );
  };

  public static getUnPublishInfoAsync = async (siteId: Guid) => {
    return new SiteUnPublishInfo(
      await AdminLiveApi.getAsync<SiteUnPublishInfo>(
        `sites/${siteId}/un-publish-info`
      )
    );
  };

  public static canPublishSiteWithPlanAsync = async (
    siteId: Guid,
    planId: Guid,
    creditCount: number
  ) => {
    return new PublishFeatureCheckStatusModel(
      await AdminLiveApi.getAsync(
        `sites/${siteId}/plans/${planId}/can-publish?creditCount=${creditCount}`
      )
    );
  };

  public static publishOldAsync = async (siteId: Guid) => {
    return new Site(
      await AdminLiveApi.putAsync<Site>(`sites/${siteId}/publish-old`)
    );
  };

  public static publishAsync = async (
    siteId: Guid,
    request: PublishSiteApiRequest
  ) => {
    return new Site(
      await AdminLiveApi.putAsync<Site>(`sites/${siteId}/publish`, request)
    );
  };

  public static unPublishAsync = async (
    siteId: Guid,
    unassignUnusedCredits: boolean
  ) => {
    return new Site(
      await AdminLiveApi.putAsync<Site>(
        `sites/${siteId.toString()}/un-publish`,
        {
          unassignUnusedCredits
        }
      )
    );
  };

  public static updatePublishScheduleAsync = async (
    siteId: Guid,
    publishAt: ScheduleTime,
    unPublishAt: ScheduleTime
  ) => {
    return new Site(
      await AdminLiveApi.putAsync<Site>(
        `sites/${siteId.toString()}/publish-schedule`,
        { publishAt, unPublishAt }
      )
    );
  };

  public static archiveAsync = async (siteId: Guid) => {
    return new Site(
      await AdminLiveApi.putAsync<Site>(`sites/${siteId.toString()}/archive`)
    );
  };

  public static unArchiveAsync = async (siteId: Guid) => {
    return new Site(
      await AdminLiveApi.putAsync<Site>(`sites/${siteId.toString()}/un-archive`)
    );
  };

  public static assignDemoCreditsAsync = (
    siteId: Guid,
    creditCount: number
  ) => {
    return AdminLiveApi.putAsync(
      `sites/${siteId.toString()}/assign-demo-credits`,
      {
        creditCount
      }
    );
  };

  public static getActiveCampaignAsync = async (siteId: Guid) => {
    const campaign = await AdminLiveApi.getAsync(
      `sites/${siteId}/campaigns/active`
    );
    return !!campaign ? new HubCampaignWithTrackingModel(campaign) : null;
  };
}
