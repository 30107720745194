import { Guid } from '@komo-tech/core/models/Guid';
import { Avatar } from '@komo-tech/ui/Avatar';
import { useEvent } from '@komo-tech/ui/hooks/useEvent';
import { SearchIcon } from '@komo-tech/ui/Icons/SearchIcon';
import { WebsiteIcon } from '@komo-tech/ui/Icons/WebsiteIcon';
import {
  createSpotlight,
  Spotlight,
  SpotlightActionData
} from '@komo-tech/ui/Spotlight';
import { FC, useState } from 'react';

import { AdminCompanyService } from '@/admin/data/AdminCompanyService';
import { AdminSiteService } from '@/admin/data/AdminSiteService';

interface Props {
  changeCompanyAsync: (
    companyId: Guid,
    workspaceId?: Guid,
    siteId?: Guid
  ) => Promise<unknown>;
}

export const [siteStore, siteSpotlight] = createSpotlight();
export const [companyStore, companySpotlight] = createSpotlight();

export const PortalSpotlight: FC<Props> = ({ changeCompanyAsync }) => {
  return (
    <>
      <SiteSpotlight changeCompanyAsync={changeCompanyAsync} />
      <CompanySpotlight changeCompanyAsync={changeCompanyAsync} />
    </>
  );
};

export const SiteSpotlight: FC<Props> = ({ changeCompanyAsync }) => {
  const [actions, setActions] = useState<SpotlightActionData[]>([]);
  const [query, setQuery] = useState('');

  const searchSitesAsync = useEvent(async () => {
    const siteData = await AdminSiteService.searchAsync({
      take: 10000
    });

    if (!siteData.hasAnyItems) {
      setActions([]);
      return;
    }

    setActions(
      siteData.items.map<SpotlightActionData>((site) => ({
        id: site.id.toString(),
        keywords: site.domain,
        label: site.name,
        description: site.companyName + ' | ' + site.domain,
        leftSection: <WebsiteIcon />,
        onClick: () => {
          changeCompanyAsync(site.companyId, site.workspaceId, site.id);
        }
      }))
    );
  });

  return (
    <Spotlight
      store={siteStore}
      actions={actions}
      query={query}
      onSpotlightOpen={searchSitesAsync}
      onSpotlightClose={() => {
        setActions([]);
      }}
      onQueryChange={setQuery}
      nothingFound={`No hubs found...`}
      searchProps={{
        leftSection: <SearchIcon />,
        placeholder: `Search hubs...`
      }}
      highlightQuery
      limit={20}
      shortcut={'mod+shift+U'}
    />
  );
};

export const CompanySpotlight: FC<Props> = ({ changeCompanyAsync }) => {
  const [actions, setActions] = useState<SpotlightActionData[]>([]);
  const [query, setQuery] = useState('');

  const searchAsync = useEvent(async () => {
    const companyData = await AdminCompanyService.searchAsync({
      take: 10000,
      skip: 0
    });

    if (!companyData.hasAnyItems) {
      setActions([]);
      return;
    }

    setActions(
      companyData.items.map<SpotlightActionData>((company) => ({
        id: company.id.toString(),
        label: company.name,
        leftSection: (
          <Avatar color="initials" name={company.getDisplayName()} />
        ),
        onClick: () => changeCompanyAsync(company.id)
      }))
    );
  });

  return (
    <Spotlight
      store={companyStore}
      actions={actions}
      query={query}
      onSpotlightOpen={searchAsync}
      onSpotlightClose={() => {
        setActions([]);
      }}
      onQueryChange={setQuery}
      nothingFound={`No companies found...`}
      searchProps={{
        leftSection: <SearchIcon />,
        placeholder: `Search companies...`
      }}
      highlightQuery
      limit={20}
      shortcut={'mod+shift+Y'}
    />
  );
};
