import React, { forwardRef } from 'react';

import { IconProps } from './IconProps';
import { getDefaultColor, IconSvg } from './IconSvg';

export const SpotifyIcon = forwardRef<HTMLSpanElement, IconProps>(
  ({ color, ...rest }, ref) => {
    return (
      <IconSvg ref={ref} {...rest}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM9.90988 14.3407C12.5353 14.3407 14.8749 14.9436 16.8882 16.1515C17.1883 16.3254 17.3637 16.504 17.3636 16.9484C17.3636 17.3821 17.012 17.6989 16.6177 17.6989C16.4235 17.6989 16.2914 17.6321 16.1103 17.5214C14.3711 16.4696 12.2019 15.9167 9.90158 15.9167C8.61739 15.9167 7.32497 16.0811 6.11583 16.3324C6.0608 16.3444 6.00187 16.3589 5.94231 16.3736L5.94162 16.3738L5.94158 16.3738C5.78845 16.4116 5.63116 16.4504 5.52482 16.4504C5.06859 16.4504 4.76611 16.0893 4.76611 15.6963C4.76611 15.1925 5.05556 14.9411 5.41785 14.872C6.90082 14.5338 8.37686 14.3407 9.90988 14.3407ZM18.3134 12.636C15.9249 11.2067 12.886 10.4238 9.7446 10.4238C7.7398 10.4238 6.36258 10.7099 5.01317 11.0804C4.51519 11.221 4.26727 11.5723 4.26727 12.0823C4.26727 12.6028 4.68906 13.0234 5.20472 13.0234C5.36255 13.0234 5.47484 12.9871 5.61375 12.9422L5.61376 12.9422L5.61377 12.9422C5.66677 12.9251 5.72365 12.9067 5.78841 12.8886C6.87374 12.6004 8.18536 12.3873 9.70529 12.3873C12.6667 12.3873 15.372 13.1567 17.3247 14.3146L17.3546 14.3321C17.5209 14.4297 17.68 14.5232 17.9261 14.5232C18.4479 14.5232 18.8614 14.1039 18.8614 13.5845C18.8613 13.1578 18.7134 12.866 18.3134 12.636ZM9.98995 6.30103C13.5125 6.30103 17.2208 7.02537 19.9285 8.61569C20.293 8.82278 20.5467 9.13386 20.5467 9.7056C20.5455 10.3595 20.02 10.8288 19.4138 10.8288C19.1707 10.8288 19.0256 10.7693 18.7969 10.6443C16.624 9.34209 13.2539 8.62514 9.99452 8.62514C8.36755 8.62514 6.71528 8.79078 5.20115 9.20152C5.15442 9.21297 5.10458 9.22764 5.05242 9.24299C4.9089 9.28522 4.74776 9.33264 4.58515 9.33264C3.94561 9.33264 3.45463 8.82636 3.45463 8.18537C3.45463 7.53394 3.85837 7.1683 4.29326 7.03962C6.00503 6.53554 7.91345 6.30103 9.98995 6.30103Z"
          fill={color || getDefaultColor()}
        />
      </IconSvg>
    );
  }
);
