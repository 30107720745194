import { useQuery } from '@tanstack/react-query';

import { AdminWorkspaceActions } from '../_actions';
import { AdminWorkspaceCacheKeys } from '../_cacheKeys';
import { AdminWorkspaceGetQuery } from '../_types';

export const useWorkspaceGet = (request: AdminWorkspaceGetQuery) =>
  useQuery(AdminWorkspaceCacheKeys.get(request.workspaceId), () =>
    AdminWorkspaceActions.getAsync(request)
  );
