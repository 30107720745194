import { Guid } from '@komo-tech/core/models/Guid';

import { AdminCache } from '../AdminCache';
import { AdminWorkspaceSearchRequest } from './_types';

const baseKey = 'AdminWorkspaces';
const cachePrefix = (workspaceId: Guid | string) => [
  baseKey,
  workspaceId?.toString()
];

export const AdminWorkspaceCacheKeys = {
  get: (workspaceId: Guid | string) => [...cachePrefix(workspaceId), 'get'],
  searchWorkspaces: (query?: AdminWorkspaceSearchRequest) => [
    baseKey,
    'search',
    { companyId: AdminCache.companyId, ...query }
  ]
};
