import { Dictionary, mapDictionary } from '@komo-tech/core/models/Dictionary';

export class AdminBillingSubscriptionUsage {
  data: Dictionary<number>;

  constructor(props?: Partial<AdminBillingSubscriptionUsage>) {
    props = props || {};
    Object.assign(this, props);
    this.data = mapDictionary(props.data, (x) => x);
  }
}
