import { Guid } from '@komo-tech/core/models/Guid';
import { useActionHandler } from '@komo-tech/ui/hooks/useActionHandler';
import { useEvent } from '@komo-tech/ui/hooks/useEvent';
import { useQueryClient } from '@tanstack/react-query';

import { AdminWorkspace } from '@/common/models/workspaces/Admin/AdminWorkspace';

import { AdminWorkspaceActions } from '../_actions';
import { AdminWorkspaceCacheKeys } from '../_cacheKeys';

interface Props {
  onSuccess?: (workspace: AdminWorkspace) => void;
}

export const useWorkspaceCreatePostmarkServer = ({ onSuccess }: Props = {}) => {
  const [handler, { isHandling }] = useActionHandler();
  const queryClient = useQueryClient();

  const callback = useEvent((workspaceId: Guid) =>
    handler(
      () => AdminWorkspaceActions.createPostmarkServerAsync(workspaceId),
      {
        onSuccess: (workspace) => {
          queryClient.setQueryData(
            AdminWorkspaceCacheKeys.get(workspace.id),
            workspace
          );
          onSuccess?.(workspace);
        }
      }
    )
  );

  return [callback, isHandling] as const;
};
