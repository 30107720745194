import { forwardRef } from 'react';

import { IconProps } from './IconProps';
import { IconSvg } from './IconSvg';

export const TriviaIcon = forwardRef<HTMLSpanElement, IconProps>(
  ({ color, ...rest }, ref) => {
    return (
      <IconSvg {...rest} ref={ref}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.3069 3.2318C14.7311 3.58537 14.7885 4.21593 14.4349 4.64021L9.13505 11H17C17.388 11 17.741 11.2245 17.9056 11.5759C18.0702 11.9273 18.0166 12.3421 17.7682 12.6402L11.1016 20.6402C10.748 21.0645 10.1174 21.1218 9.69316 20.7682C9.26888 20.4147 9.21156 19.7841 9.56512 19.3598L14.865 13H7.00001C6.61199 13 6.259 12.7756 6.09442 12.4242C5.92984 12.0728 5.98339 11.6579 6.23179 11.3598L12.8985 3.35984C13.252 2.93556 13.8826 2.87824 14.3069 3.2318Z"
          fill={color || 'currentColor'}
        />
      </IconSvg>
    );
  }
);
