import { useAdminBillingSubscriptionClone } from './_useClone';
import { useAdminBillingSubscriptionCreate } from './_useCreate';
import { useAdminBillingSubscriptionGet } from './_useGet';
import { useAdminBillingSubscriptionGetUsage } from './_useGetUsage';
import { useAdminBillingSubscriptionSearch } from './_useSearch';
import { useAdminBillingSubscriptionUpdate } from './_useUpdate';

export const AdminBillingSubscriptionHooks = {
  useGet: useAdminBillingSubscriptionGet,
  useSearch: useAdminBillingSubscriptionSearch,
  useGetUsage: useAdminBillingSubscriptionGetUsage,
  useCreateHandle: useAdminBillingSubscriptionCreate.uncontrolled,
  useUpdateHandle: useAdminBillingSubscriptionUpdate.uncontrolled,
  useCloneHandle: useAdminBillingSubscriptionClone.uncontrolled,
  controlled: {
    useCreateHandle: useAdminBillingSubscriptionCreate.controlled,
    useUpdateHandle: useAdminBillingSubscriptionUpdate.controlled,
    useCloneHandle: useAdminBillingSubscriptionClone.controlled
  }
};
