import { asBoolean } from '@komo-tech/core/utils/boolean';
import { roundTo2Decimals } from '@komo-tech/core/utils/number';

import { BillingFeatureTypeCategoryDefinition } from '@/common/models/billing/features/shared/BillingFeatureTypeDefinition';
import { BillingFeatureTypes } from '@/common/models/billing/features/shared/BillingFeatureTypes';
import { BillingFeatureTypeVariableTypes } from '@/common/models/billing/features/shared/BillingFeatureTypeVariableTypes';
import { AdminBillingSubscriptionModifier } from '@/common/models/billing/subscriptions/modifiers/Admin/AdminBillingSubscriptionModifier';
import { BillingSubscriptionModifierTypes } from '@/common/models/billing/subscriptions/modifiers/shared/BillingSubscriptionModifierTypes';

export class BillingSubscriptionEntitlement {
  name: string;
  description: string;
  order: number;
  featureType: BillingFeatureTypes;
  featureCategory: BillingFeatureTypeCategoryDefinition;
  variableType: BillingFeatureTypeVariableTypes;
  value?: number;
  modifier?: AdminBillingSubscriptionModifier;
  modifierOnly?: boolean;
  usage?: number;

  constructor(props?: Partial<BillingSubscriptionEntitlement>) {
    props = props || {};
    Object.assign(this, props);
    this.modifierOnly = asBoolean(props.modifierOnly, false);
    this.featureCategory = new BillingFeatureTypeCategoryDefinition(
      props.featureCategory
    );

    if (props.modifier) {
      this.modifier = new AdminBillingSubscriptionModifier(props.modifier);
    }
  }

  get isUnlimited() {
    return this.value === null;
  }

  get valueDisplay() {
    if (this.modifierOnly) {
      return 'No';
    }

    if (this.variableType === BillingFeatureTypeVariableTypes.Flag) {
      return 'Yes';
    }

    if (this.isUnlimited) {
      return 'Unlimited';
    }

    if (
      this.modifier &&
      this.modifier.type === BillingSubscriptionModifierTypes.AddQuantity &&
      !this.modifier.isUnlimited
    ) {
      return (this.value + this.modifier.value).toLocaleString();
    }

    return this.value?.toLocaleString();
  }

  // Checks if the modifier is a total replacement of the entitlement rather than an augmentation
  get modifierIsReplacement() {
    return (
      this.modifierOnly ||
      (this.modifier &&
        ([
          BillingSubscriptionModifierTypes.Enable,
          BillingSubscriptionModifierTypes.Disable
        ].includes(this.modifier.type) ||
          this.modifier.isUnlimited ||
          this.isUnlimited))
    );
  }

  get usageDisplay() {
    if (this.usage === null) {
      return '-';
    }

    return this.usage?.toLocaleString();
  }

  get usagePercentageDisplay() {
    const value = this.usagePercentage;
    return value !== null ? `(${value.toLocaleString()}%)` : '';
  }

  get entitlementValue() {
    if (
      this.variableType !== BillingFeatureTypeVariableTypes.Number ||
      this.modifier?.type === BillingSubscriptionModifierTypes.Disable
    ) {
      return null;
    }

    if (
      this.modifier &&
      this.modifier.type === BillingSubscriptionModifierTypes.AddQuantity
    ) {
      if (this.modifier.isUnlimited) {
        return null;
      }

      if (this.modifierOnly) {
        return this.modifier.value;
      }

      return this.value + this.modifier.value;
    }

    if (!this.isUnlimited) {
      return this.value;
    }

    // The entitlement does not have a relevant number value
    return null;
  }

  get usagePercentage() {
    if (
      this.usage === null ||
      this.entitlementValue === null ||
      this.entitlementValue === 0
    ) {
      return null;
    }

    return roundTo2Decimals((this.usage / this.entitlementValue) * 100);
  }

  get hasWarningLevel() {
    return this.featureType === BillingFeatureTypes.Contacts;
  }
}
