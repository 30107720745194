import React, { forwardRef } from 'react';

import { IconProps } from './IconProps';
import { getDefaultColor, IconSvg } from './IconSvg';

export const IssuuCardIcon = forwardRef<HTMLSpanElement, IconProps>(
  ({ color, ...rest }, ref) => {
    return (
      <IconSvg ref={ref} {...rest}>
        <path
          d="M2.8125 2C2.375 2 2 2.375 2 2.8125V12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2H2.8125ZM12.125 5C15.9375 5.0625 19.0625 8.1875 19 12C19 15.8125 15.8125 18.875 12 18.875C8.1875 18.875 5.125 15.6875 5.125 11.875C5.1875 8 8.3125 4.9375 12.125 5ZM12.125 6.875C9.3125 6.875 7 9.125 7 11.9375C7 14.75 9.25 17.0625 12.0625 17.0625C13.4375 17.0625 14.6875 16.5625 15.6875 15.625C16.625 14.6875 17.1875 13.375 17.1875 12.0625C17.1875 9.1875 14.9375 6.875 12.125 6.875ZM12.125 9.375C13.5625 9.375 14.6875 10.5625 14.6875 12C14.6875 13.4375 13.5 14.5625 12.0625 14.5625C8.625 14.5 8.6875 9.375 12.125 9.375Z"
          fill={color || getDefaultColor()}
        />
      </IconSvg>
    );
  }
);
