import { Guid } from '@komo-tech/core/models/Guid';
import { QueryClient } from '@tanstack/react-query';

import { AdminCompanyService } from '@/admin/data/AdminCompanyService';
import { AdminBillingSubscription } from '@/common/models/billing/subscriptions/Admin/AdminBillingSubscription';

import { AdminBillingSubscriptionSearchQuery } from './_types';

const base = ['AdminBillingSubscription'];
const baseGet = [...base, 'Get'];
const baseSearch = [...base, 'Search'];
const baseUsage = [...base, 'Usage'];
const get = (id: string | Guid) => [...baseGet, id?.toString()];

export const AdminBillingSubscriptionCacheKeys = {
  base,
  baseGet,
  baseSearch,
  baseUsage,
  get,
  search: (query: AdminBillingSubscriptionSearchQuery) => [
    ...baseSearch,
    { ...query }
  ],
  getUsage: (id: string | Guid) => [...baseUsage, id?.toString()],
  fns: {
    updateAsync: async (
      client: QueryClient,
      subscription: AdminBillingSubscription
    ) => {
      await client.invalidateQueries(baseSearch);
      await client.invalidateQueries(
        AdminCompanyService.cacheKeys.activeSubscription(subscription.companyId)
      );
      await client.invalidateQueries(AdminCompanyService.cacheKeys.baseSearch);
      await client.invalidateQueries(
        AdminCompanyService.cacheKeys.company(subscription.companyId)
      );
      client.setQueryData(get(subscription.id), subscription);
    }
  }
};
